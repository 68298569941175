import { DependencyList, EffectCallback, RefObject, useEffect, useRef, useState, useId } from "react";
import debounce from "lodash/debounce";
import { isRetailer, loadImage } from "./utils";
import { getPostal } from "./utils/postalUtils";
import Debug from "./Debug";
import { POSTAL_CHANNEL_T1_NAVIGATION, POSTAL_TOPIC_PRIMARY_NAV, POSTAL_TOPIC_PRIMARY_NAV_REQUEST } from "./constants";
import { useCommonSelector } from "./redux/commonStore";
import { useWindowDimensionsContext } from "./containers/WindowDimensionsProvider";

// Disclaimer: This hook does not work on dialog elements that reside ion the toplayer
// For dialog element in the toplayer, use useCloseOnOutsideDialog instead
export const useCloseOnOutsideClick = (
    element: React.RefObject<HTMLElement>,
    closeFunction: () => void,
    disable = false,
    // Pass a custom function to check if the closeFunction should be run on click outside,
    // this is useful for allowing some elements to be clicked. Return true to allow clicks without the closeFunction to be run
    checkIgnoreFunction?: (event: MouseEvent | TouchEvent) => boolean,
): void => {
    useEffect(() => {
        if (element.current && !disable) {
            const outsideClick = (event: MouseEvent | TouchEvent): void => {
                // Only allow the closeFunction to trigger when clicking outside of a tooltip or modal and not when clicking the tooltip itself.
                const currentTarget = event.target as Element;

                const shouldIgnore =
                    currentTarget.hasAttribute("data-ignore-outside-clicks") ||
                    currentTarget.closest("[data-ignore-outside-clicks]");

                // Check if Element instead of HTMLElement so this also works for SVGElements etc
                if (
                    element.current &&
                    currentTarget instanceof Element &&
                    !element.current.contains(currentTarget) &&
                    !shouldIgnore
                ) {
                    if ((checkIgnoreFunction && !checkIgnoreFunction(event)) || !checkIgnoreFunction) closeFunction();
                }
            };

            window.addEventListener("mousedown", outsideClick);
            window.addEventListener("touchstart", outsideClick);

            return () => {
                window.removeEventListener("mousedown", outsideClick);
                window.removeEventListener("touchstart", outsideClick);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element, closeFunction, disable]);
};

export const useDisableBodyScroll = (disabled?: boolean): void => {
    useEffect(() => {
        if (disabled) return;

        // Source: https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
        const bodyStyle = document.body.style;
        const currentScrollPosition = window.scrollY;
        bodyStyle.top = `-${currentScrollPosition}px`;
        bodyStyle.position = "fixed";
        bodyStyle.width = "100%";
        // eslint-disable-next-line consistent-return
        return () => {
            bodyStyle.position = "";
            bodyStyle.top = "";
            bodyStyle.width = "";
            window.scrollTo(0, currentScrollPosition);
        };
    }, [disabled]);
};

/**
 * Returns true after a first render. This can be useful for css transitions on a component when it first appears (fade-ins etc)
 */
export const useShowAfterFirstRender = (show = true): boolean => {
    const [showComponent, setShowComponent] = useState<boolean>(false);

    useEffect(() => {
        if (!showComponent && show) setShowComponent(true);
        else if (!show && showComponent) setShowComponent(false);
    }, [show, showComponent]);

    return showComponent;
};

/**
 * Helper to easily use an InterSectionObserver with React components.
 *
 * @param containerRef - Ref to the component where the observer will be attached to
 * @param callback - (async) callback which will be called when containerRef is into view
 * @param disable - Optionally disable this hook
 * @param options - Optional IntersectionObserver options
 * @param exitCallback - Optional callback which will be called when containerRef is out of view
 */
export const useIntersectionObserver = (
    containerRef: RefObject<Element>,
    callback: (() => Promise<void>) | (() => void),
    disable: boolean = false,
    options?: IntersectionObserverInit,
    exitCallback?: (() => Promise<void>) | (() => void),
): void => {
    useEffect(() => {
        const container = containerRef.current;
        if (!container || disable) return;

        // IE => Execute callback immediately. Not ideal for performance but it is IE which is slow anyway.
        if (!window.IntersectionObserver) {
            callback();
            return;
        }

        const observer = new IntersectionObserver(async ([entry]) => {
            if (entry.isIntersecting) await callback();
            if (!entry.isIntersecting && exitCallback) await exitCallback();
        }, options);

        observer.observe(container);

        // eslint-disable-next-line consistent-return
        return () => {
            observer.disconnect();
        };
    }, [callback, containerRef, options, disable]);
};

type UseLoadImageType = {
    loading: boolean;
    imageLoaded: boolean;
    loadFailed: boolean;
};

/**
 *  Starts loading an image when startLoading is set to true.
 */
export const useLoadImage = (startLoading: boolean, src: string, srcSet?: string): UseLoadImageType => {
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadFailed, setLoadFailed] = useState<boolean>(false);

    useEffect(() => {
        const imageLoader = async (): Promise<void> => {
            try {
                setLoading(true);
                await loadImage(src, srcSet);
                setImageLoaded(true);
            } catch (ex) {
                setLoadFailed(true);
                Debug.error(`Failed to load image: ${src}`);
            }
            setLoading(false);
        };

        if (startLoading && !imageLoaded && !loadFailed) imageLoader();
    }, [startLoading, imageLoaded, loadFailed, src, srcSet]);

    return { loading, imageLoaded, loadFailed };
};

type UseWindowDimensionsType = {
    width: number;
    height: number;
};

/**
 * @deprecated Please use useWindowDimensionsContext() directly instead.
 * Always returns the latest window width and height. Useful for components that depend on window size for some calculations.
 */
export const useWindowDimensions = (): UseWindowDimensionsType => {
    return useWindowDimensionsContext();
};

/**
 * Returns the current visible height of the AEM navigation header.
 */
export const useAemNavOffset = (): number => {
    const commonSettings = useCommonSelector((state) => state.commonSettings);

    // We make a guess here so the menu seems good enough before js init
    const [primaryNavHeight, setPrimaryNavHeight] = useState(
        // See explanation of query.isStandalone vs commonSettings.isStandalone in the QueryType definition.
        isRetailer(commonSettings) || commonSettings.query.isStandalone ? 0 : commonSettings.isStandalone ? 80 : 115,
    );

    // Listen to postal event for when primary nav is hidden, the event is also sent on page init and breakpoint change
    useEffect(() => {
        const postal = getPostal();
        const subscription = postal.subscribe({
            channel: POSTAL_CHANNEL_T1_NAVIGATION,
            topic: POSTAL_TOPIC_PRIMARY_NAV,
            callback: (event: any) => {
                setPrimaryNavHeight(event?.visibleHeaderHeight || 0);
            },
        });

        postal.publish({
            channel: POSTAL_CHANNEL_T1_NAVIGATION,
            topic: POSTAL_TOPIC_PRIMARY_NAV_REQUEST,
        });

        return () => subscription.unsubscribe();
    }, []);

    return primaryNavHeight;
};

/**
 * Debounced version of useEffect. Does what the name implies.
 * Use it in the same way as useEffect, but with the added debounce prop.
 */
export const useDebouncedEffect = (effect: EffectCallback, debounceMs: number, deps?: DependencyList): void => {
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    useEffect(() => {
        // Clear timeout if it has already been running as we have a new function now.
        if (timeout.current) clearTimeout(timeout.current);

        // Create a new timeout which should expire after given amount of ms.
        timeout.current = setTimeout(() => {
            effect();
            timeout.current = null;
        }, debounceMs);
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

export const SCROLL_THRESHOLD = 25;

/**
 * Returns true when the user has scrolled down the page pas a certain threshold.
 * Will return false again if the users scrolls back up to the start.
 */
export const useHasScrolled = (viewportRef?: RefObject<HTMLDivElement>): boolean => {
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        const updateScroll = debounce(() => {
            // don't update when useDisableBodyScroll is active
            if (document.body.style.overflow === "hidden" || viewportRef?.current?.style.overflow === "hidden") return;

            if (viewportRef?.current) {
                setIsScrolling(viewportRef.current.scrollTop > SCROLL_THRESHOLD);
            } else {
                setIsScrolling(window.pageYOffset > SCROLL_THRESHOLD);
            }
        }, 10);

        if (viewportRef?.current) {
            viewportRef.current.addEventListener("scroll", updateScroll);
            return () => viewportRef.current?.removeEventListener("scroll", updateScroll);
        } else {
            window.addEventListener("scroll", updateScroll);
            return () => window.removeEventListener("scroll", updateScroll);
        }
    }, [viewportRef]);

    return isScrolling;
};

/**
 * Method which returns a unique id (used for ARIA implementations)
 */
export const useUniqueId = (): string => {
    return useId();
};
