import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as TooltipStyles from "../../../../../../../common-deprecated/styles/v2/toyota/components/Tooltip";
import * as Button from "../../../../../../../common-deprecated/styles/v2/toyota/components/Button";
import { getDirection } from "../../../../../../../common-deprecated/themes/common";

export const PriceCollection = styled.span`
    display: flex;
    flex-direction: column;
`;

export const Cash = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.primaryBlack};
    white-space: nowrap;

    ${TooltipStyles.PopoverButton} {
        margin-${getDirection("left")}: ${theme.space[1]}px;
        line-height: 2.4rem;
        .icon::before {
            position: relative;
            top: 0;
            color: ${theme.colors.primaryBlack};
        }
    }

`;

export const CashDiscount = styled(Cash)`
    text-decoration: line-through;
`;

export const Monthly = styled.div`
    display: flex;
    align-items: center;
    max-width: 100%;
    font-family: ${theme.fonts.base};
    font-size: 1.3rem;
    line-height: 2rem;

    &, b {
        font-weight: normal !important;
        color: ${theme.colors.grey7} !important;
    }

    ${TooltipStyles.PopoverButton} {
        margin-${getDirection("left")}: ${theme.space[1]}px;
        line-height: 2.4rem;
        .icon::before {
            position: relative;
            top: 0;
            font-size: 1.3rem;
            line-height: 2.4rem;
            color: ${theme.colors.grey7};
        }
    }

    ${TooltipStyles.BoxWrapper} {
        span {
            white-space: normal;
        }
    }

`;

export const MonthlyPriceDisclaimer = styled.button`
    display: flex;
    align-items: center;
    padding: 0;
    background: transparent;
    border: none;
`;

export const DisclaimerIcon = styled.span`
    display: flex;
    align-items: center;
    color: ${theme.colors.grey7};
`;

export const MonthlyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const LegalText = styled.p`
    margin: 0;
    text-align: start;
    text-indent: 0;

    ${Button.Link} {
        text-decoration: underline;
        color: ${theme.colors.grey7};
    }
`;
