import { ModalMapType } from "../../../../../common-deprecated/types/CommonTypes";
import {
    CommonModalIdsType,
    MODAL_CONTENT,
    MODAL_ERRORS,
    MODAL_FINANCE_DISCLAIMER,
    MODAL_OK,
} from "../../../../../common-deprecated/utils/modalConstants";
import OkModal from "../../../../../common-deprecated/components/modals/aem/OkModal";
import FinanceDisclaimerModal from "../../../../../common-deprecated/components/modals/aem/FinanceDisclaimerModal";
import ErrorsModal from "../../../../../common-deprecated/components/modals/status/ErrorsModal";
import { aemToyotaDebugModalMap } from "../../../../../common-deprecated/components/modals/aem/modals";
import UscContentModal from "./UscContentModal";

export const uscToyotaModalMap: ModalMapType<CommonModalIdsType> = {
    ...aemToyotaDebugModalMap,
    [MODAL_OK]: OkModal,
    // In USC we have a USC specific content modal which uses a different layout
    [MODAL_CONTENT]: UscContentModal,
    [MODAL_FINANCE_DISCLAIMER]: FinanceDisclaimerModal,
    [MODAL_ERRORS]: ErrorsModal,
};
