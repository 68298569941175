import styled from "styled-components";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Title = styled.h2`
    margin: 0;
    padding: 0;
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: none;
    color: ${theme.colors.primaryBlack};
`;
