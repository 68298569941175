import { css } from "styled-components";

export const Colors = css`
    --color-primary-white: #fff;
    --color-primary-black: #282830;
    --color-highlight-black: #15151b; /* Only used for highlights of black on black backgrounds. Not for text. */

    /* stylelint-disable-next-line color-hex-length */
    --color-primary-red: #ff0022; /* Used for branded elements and dividers */
    --color-highlight-red: #d40605; /* Used for hover states of the primary red */

    --color-green: #4caf50; /* Success */

    --color-grey-1: #f5f5f5; /* Used for solid backgrounds */
    --color-grey-4: #e4e4e4; /* Used for dividers */
    --color-grey-6: #a8aaac; /* Used for secondary titles */
    --color-grey-7: #6c7073; /* Used for labels */

    --color-blue: #0072f0; /* Used for outline colours. Hybrid colours. */
    --color-indigo: #3f51b5; /* Fuelcell */
    --color-bz4x: #003641;
    --color-bz4x-hover: #46a4b0;

    --color-eco-a: #33a357;
    --color-eco-b: #79b752;
    --color-eco-c: #c3d545;
    --color-eco-d: #fff12c;
    --color-eco-e: #edb731;
    --color-eco-f: #d66f2c;
    --color-eco-g: #cc232a;
    --color-eco-default: #020202;
`;

export const Dropshadows = css`
    --dropshadow-sm: 0px 4px 4px rgba(0, 0, 0, 0.25);
    --dropshadow-md: 0px 15px 80px rgba(0, 0, 0, 0.04), 0px 4.52206px 24.1177px rgba(0, 0, 0, 0.05),
        0px 1.87823px 10.0172px rgba(0, 0, 0, 0.03), 0px 0.67932px 3.62304px rgba(0, 0, 0, 0.02);
    --dropshadow-lg: 0px 15px 80px rgba(0, 0, 0, 0.04), 0px 4.52206px 24.1177px rgba(0, 0, 0, 0.05),
        0px 1.87823px 10.0172px rgba(0, 0, 0, 0.03), 0px 0.67932px 3.62304px rgba(0, 0, 0, 0.02);
`;

export const Fonts = css`
    --font-light: "Toyota Light", Arial, Helvetica, sans-serif;
    --font-book: "Toyota Book", Arial, Helvetica, sans-serif;
    --font-regular: "Toyota Regular", Arial, Helvetica, sans-serif;
    --font-semi-bold: "Toyota Semibold", Arial, Helvetica, sans-serif;
    --font-bold: "Toyota Bold", Arial, Helvetica, sans-serif;
`;

/* Use px for default spacing since they shouldn't have to increase when increasing base font-size */
export const Spacers = css`
    --spacing-0: 0;
    --spacing-1: 8px;
    --spacing-2: 16px;
    --spacing-3: 24px;
    --spacing-4: 32px;
    --spacing-5: 40px;
    --spacing-6: 48px;
    --spacing-7: 56px;
    --spacing-8: 64px;
    --spacing-9: 72px;
    --spacing-10: 80px;
    --spacing-11: 120px;
`;

export const ZIndex = css`
    --z-index-popup: 700;
    --z-index-dropdown: 1000;
    --z-index-navbar: 1010;
    --z-index-sticky: 1020;
    --z-index-fixed: 1030;
    --z-index-modalbackdrop: 1040;
    --z-index-modal: 1050;
    --z-index-overlay: 1060;
    --z-index-tooltip: 1070;
`;
