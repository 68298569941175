import { UscFormExternalDataType } from "../../../used-stock-cars-form/redux/reducers/UscFormExternalDataReducer";
import { DealerResultType } from "../../../../common-deprecated/utils/dealerConstants";
import { UscVehicleType } from "../uscUtils";

// To avoid circular dependencies we're keeping these modals separate from the component file.
// Modal constants. Keep them here as these should be reused wherever the modal is shown.
export const MODAL_USC_FORM = "uscForm";
export type ContactDealerType = {
    type: typeof MODAL_USC_FORM;
    vehicleForSaleId: string;
    uscContext: "used" | "stock";
    modalTitle: string; // Localized string.

    // Optional data usually provided by the USC component.
    externalData?: UscFormExternalDataType;
    // Vehicle or dealer will be fetched if it is not available.
    vehicle?: UscVehicleType;
    dealer?: DealerResultType;
};
