import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { fetchUsedCarResults } from "../../redux/actions/CarResultsActions";
import { CAR_FILTER_ID, DEFAULT_RESULT_COUNT } from "../../utils/constants/filterConstants";
import { scrollTargetIntoView } from "../../../../common-deprecated/utils";
import { CarFilterDispatchType, useCarFilterSelector } from "../../redux/store";
import { getContentBlockConfig } from "../../utils/filters";
import { CarResultItemType } from "../../redux/reducers/CarResultsReducer";
import { UsedCarResultType } from "../../../shared-logic/types/UscCommonTypes";
import {
    getCarFilterSessionStorage,
    getSessionStorageParams,
    setCarFilterSessionStorage,
} from "../../utils/sessionStorage";
import { useAemNavOffset } from "../../../../common-deprecated/hooks";
import { getTizenVersion } from "../../../../shared-logic/features/retailer/utils/utils";

type UseCarResultsContainerType = {
    renderedResults: CarResultItemType<UsedCarResultType>[];
    resultKey: string;
    contentBlockIndex: number | null;
};

/**
 * Used in CarResultsContainer.
 */
const useCarResultsContainer = (): UseCarResultsContainerType => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const initialized = useCarFilterSelector((state) => state.carResults.initialized);
    const dfCarResults = useCarFilterSelector((state) => state.carResults.dfCarResults);
    const filterContext = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const contentBlockConfig = useCarFilterSelector(({ carFilterSettings, carFilters }) =>
        getContentBlockConfig(carFilterSettings, carFilters.currentFilter),
    );
    const pagination = useCarFilterSelector((state) => state.carFilters.pagination);
    const scrollToCarFilter = useCarFilterSelector((state) => state.commonSettings.query.scrollToCarFilter);
    const sessionStorageParams = useCarFilterSelector(getSessionStorageParams, shallowEqual);
    const [contentBlockIndex, setContentBlockIndex] = useState<null | number>(null);
    const siteNavOffset = useAemNavOffset();
    const tizenVerion = getTizenVersion();
    const showMoreFilters = useCarFilterSelector((state) => state.carFilters.showMoreFilters);
    const loadingMore = useCarFilterSelector((state) => state.carResults.loadingMore);

    // ----------------------------------------------------------------------
    // Results components
    // ----------------------------------------------------------------------
    const sourceResults: CarResultItemType<UsedCarResultType>[] = dfCarResults;

    // Only render placeholders or actual loaded cars. Cars that are loading and are not a placeholder should be hidden.
    const renderedResults = sourceResults.filter(
        ({ result, placeholder, loading }) => placeholder || (!loading && result),
    );
    const resultKey = renderedResults
        .map((result) => `${result.result?.id || ""}-${String(result.placeholder)}`)
        .join("-");

    // ----------------------------------------------------------------------
    // Initialisation logic
    // ----------------------------------------------------------------------

    useEffect(() => {
        // If the component is not initialized it means we need to load initial data.
        if (!initialized) {
            // The VP block needs to be shown in the first 6 result set.
            // Subtract 1 from the default result count to make space for the VP block.
            const initResultCount = contentBlockConfig ? DEFAULT_RESULT_COUNT - 1 : DEFAULT_RESULT_COUNT;
            dispatch(fetchUsedCarResults(filterContext, 0, initResultCount, false, true));
            // Determine where to show the value proposition block, which is at a random place between the first 5 results.
            // This only needs to happen on init and only if we're fetching the very first set of results.
            if (contentBlockConfig) setContentBlockIndex(Math.floor(Math.random() * 5));

            // We came from an SSR'd page (as the results have been initialized) to the first page of the filter.
        } else if (initialized && pagination.page === 1) {
            if (contentBlockConfig) setContentBlockIndex(Math.floor(Math.random() * 5));
        }

        // Add scroll listener, used to scroll back to the users position when returning to the filter.
        const updatePositionToStore = debounce((): void => {
            setCarFilterSessionStorage(sessionStorageParams, { carFilterResultPosition: window.scrollY });
        }, 300);

        const updateScroll = (): void => {
            updatePositionToStore();
        };

        window.addEventListener("scroll", updateScroll);
        return () => window.removeEventListener("scroll", updateScroll);
    }, [pagination.page]);

    // ----------------------------------------------------------------------
    // "Autoscroll" logic
    // ----------------------------------------------------------------------

    // Scroll to the top of the Car Filter when page changes.
    useEffect(() => {
        const carFilterElement = document.getElementById(CAR_FILTER_ID);
        if (carFilterElement && !loadingMore) {
            try {
                window.scrollTo({
                    top: carFilterElement.offsetTop,
                    behavior: "smooth",
                });
            } catch (ignored) {
                // Retailer screens (using Tizen 4) don't support the behavior option.
                window.scrollTo(0, carFilterElement.offsetTop);
            }
        }
    }, [pagination.page]);

    // Scroll the filter into view when required, see OR-4183
    // Only do this if we dont already have a position saved as that will trigger scroll logic further below.
    useEffect(() => {
        const carFilterResultPosition = getCarFilterSessionStorage(sessionStorageParams)?.carFilterResultPosition;
        if (scrollToCarFilter && !carFilterResultPosition) {
            // eslint-disable-next-line no-unused-expressions
            document.getElementById(CAR_FILTER_ID)?.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    // "Scrollback" functionality where the user is brought back to their last position if they navigated away from the filter.
    const scrolled = useRef<boolean>(false);
    useEffect(() => {
        const carFilterResultPosition = getCarFilterSessionStorage(sessionStorageParams)?.carFilterResultPosition;
        // Only trigger once and after the initialisation is done.
        if (!scrolled.current && initialized && carFilterResultPosition && showMoreFilters) {
            // If a position is found, scroll to it after the render is done to make sure all placeholders are rendered.
            window.requestAnimationFrame(() => {
                window.scrollTo(0, carFilterResultPosition);
                setCarFilterSessionStorage(sessionStorageParams, { carFilterResultPosition: 0 });
            });

            scrolled.current = true;
        }
    }, [initialized, showMoreFilters]);

    // OR-7791
    // When the user scrolls down to the button of the car-filter and selects a filter which results in less 12 results,
    // the car-filter will shrink because there are fewer results, but the scroll position will remain the same.
    // Because the scroll position remains the same it looks like the user scrolled down or the car-filter scrolled up, but this didn't happen.
    // It just disappeared out of view because it became smaller.
    // We fix this by scrolling the car-filter back into view when the user's scroll position appears below the car-filter container after the results were updated.
    // Added a margin of 100 to execute this logic also when the user only sees the last 100px of the car-filter
    const prevScrollY = useRef<number>(0);
    useEffect(() => {
        const carFilterElement = document.getElementById(CAR_FILTER_ID);

        const carFilterBottomPosition = carFilterElement?.getBoundingClientRect().bottom;

        if (carFilterBottomPosition && carFilterBottomPosition - 100 < prevScrollY.current) {
            scrollTargetIntoView(CAR_FILTER_ID, siteNavOffset, !!(tizenVerion && tizenVerion < 5), true);
        }
    }, [renderedResults.length]);

    return { renderedResults, resultKey, contentBlockIndex };
};

export default useCarResultsContainer;
