import styled, { css } from "styled-components";

// STYLES

// COMPONENTS
export const Preloader = styled.div<{ delayedVisibility?: boolean; size?: number; hasLabel?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    &::before,
    &::after {
        position: absolute;
        top: ${({ hasLabel }) => (hasLabel ? "calc(50% - 15px)" : "50%")};
        left: 50%;
        width: ${(props) => props.size || 45}px;
        height: ${(props) => props.size || 45}px;
        /* stylelint-disable */
        margin-top: calc(-${(props) => (props.size || 45) / 2}px);
        margin-left: calc(-${(props) => (props.size || 45) / 2}px);
        /* stylelint-enable */
        content: "";
        background-clip: padding-box;
        border-top: 2px solid var(--color-grey-7);
        border-right: 2px solid var(--color-grey-7);
        border-radius: 50%;

        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    &::before {
        border-top: 2px solid var(--color-grey-7);
        border-bottom: 2px solid var(--color-grey-1);
        border-left: 2px solid var(--color-grey-1);
        animation-name: spin-loader;
        z-index: 10;
    }

    &::after {
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 1px solid var(--color-grey-7);
        animation-name: spin-loader;
        z-index: 20;
    }
    @keyframes spin-loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /* Use this boolean when you want the showing of the loader to be delayed a half second */
    /* Useful for stopping the loader flashing when an item loads instantly */
    ${({ delayedVisibility }) =>
        delayedVisibility !== undefined &&
        css`
            @keyframes fadeInCutOut {
                0% {
                    opacity: 0;
                }
                99% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

            ${delayedVisibility
                ? css`
                      animation: fadeInCutOut 0.3s ease-in;
                  `
                : css`
                      opacity: 0;
                  `};
        `};
`;

export const LoadingWrapper = styled.div<{ height?: string }>`
    position: relative;
    margin-inline: auto calc(var(--spacing-3) / 2);

    @supports not (margin-inline: 0) {
        margin-left: auto;
        margin-right: calc(var(--spacing-3) / 2);
    }

    ${({ height }) =>
        height &&
        css`
            height: ${height};
        `};
`;
