import { DfCarSortOrder, UsedCarDropdownSpec } from "../../utils/constants/filterConstants";
import {
    StockCarFilterId,
    UsedCarFilterId,
    UscContext,
    UscAspectRatioType,
    LegalSpecConfigType,
    UscObjectFitType,
    UscCustomerPromiseBannerType,
} from "../../../shared-logic/types/UscCommonTypes";
import {
    CAR_FILTER_SETTINGS_INIT,
    CAR_FILTER_SETTINGS_OVERRIDE,
    CAR_FILTER_SETTINGS_SET_USE_GLOBAL_STORE,
    CarFilterSettingsActionsType,
} from "../actions/CarFilterSettingsActions";
import { carFilterLabels, CarFilterLabelType } from "../../utils/constants/labels";
import { SimilarCarFilterId } from "../../utils/constants/similarCarConstants";
import { QuickSpecConfigType, UscEnv } from "../../../shared-logic/utils/uscUtils";
import { MultipleChoiceValueSortOption } from "../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";

export type CarFilterContentBlockConfigType = {
    context: UscContext;
    items: string[];
    title: string;
    imageUrl: string;
};

export type CarFilterComingSoonBlockConfigType = {
    title: string;
    subtitle: string;
    imageUrl: string;
    link: string;
    showOnlineAvailable?: boolean;
    showComingSoon?: boolean;
};

export type CarFilterSettingsType = {
    initialized: boolean;
    useGlobalStore: boolean; // Indicates whether the global store should be used. This allows the Car Filter and Car Filter Header (top filters) to share the same store.
    uscEnv: UscEnv;
    distributorCode: string;
    stockCarDropdownSpecConfig: QuickSpecConfigType<UsedCarDropdownSpec>[];
    stockCarsShowLocation: boolean;
    usedCarsShowLocation: boolean;
    usedCarDropdownSpecConfig: QuickSpecConfigType<UsedCarDropdownSpec>[];
    usedCarResultAspectRatio: UscAspectRatioType;
    stockCarResultAspectRatio: UscAspectRatioType;
    usedCarResultObjectFit: UscObjectFitType;
    stockCarResultObjectFit: UscObjectFitType;
    usedCarEnableZeroMileage: boolean;
    usedCarsWarrantyFilterConfig: {
        // Additional warranty filter config not covered in the default AEM config. Mostly relevant during UCL setup.
        showAnyWarranty: boolean; // False by default, this enables the generic 'Toyota Approved' filter which should just show any kind of vehicle.
        showTerms: boolean; // True by default, this enables the 12/24/36/... month warranty filter.
    };
    usedCarSimilarCarsConfig: { filterId: SimilarCarFilterId; value?: string }[];
    stockCarSimilarCarsConfig: { filterId: SimilarCarFilterId; value?: string }[];
    usedCarsEnableFinance: boolean;
    stockCarsEnableFinance: boolean;
    usedCarsEnableStatusBadge: boolean;
    usedCarsEnableBiasedSort: boolean;
    stockCarsEnableStatusBadge: boolean;
    urlUsedCarsDetailPage: string;
    urlUsedCarsContactPage: string; // Not relevant for AEM
    urlUsedCarsLandingPage: string;
    urlStockCarsLandingPage: string;
    urlStockCarsDetailPage: string;
    urlStockCarsContactPage: string; // Not relevant for AEM
    dealerRangeValues: number[];
    contentBlockConfigurations: CarFilterContentBlockConfigType[];
    usedCarMainFilters: UsedCarFilterId[];
    stockCarMainFilters: StockCarFilterId[];
    stockCarDisableCarCategory: boolean; // Can this setting be removed in favour of the disabled filters (OR-7957)
    usedCarDisableCarCategory: boolean; // Can this setting be removed in favour of the disabled filters (OR-7957)
    usedCardisableMonthInRegistrationSpec: boolean;
    stockCardisableMonthInRegistrationSpec: boolean;
    comingSoonBlockConfigurations: CarFilterComingSoonBlockConfigType[];
    usedCarLegalSpecConfig: LegalSpecConfigType;
    stockCarLegalSpecConfig: LegalSpecConfigType;
    usedCarShowZeroCo2SpecValues: boolean;
    stockCarShowZeroCo2SpecValues: boolean;
    usedCarsHideTopFilters: boolean;
    stockCarsHideTopFilters: boolean;
    usedCarsHideZeroResultCountFilters: boolean;
    stockCarsHideZeroResultCountFilters: boolean;
    usedCarsLocalContactDealerFormUrl: string;
    stockCarsLocalContactDealerFormUrl: string;
    topFiltersBackgroundImageUrl: string;
    usedCarsEnableDynamicPriceFilter: boolean;
    stockCarsEnableDynamicPriceFilter: boolean;
    usedCarsSortOptions: string[];
    stockCarsSortOptions: string[];
    usedCarsMultipleChoiceValueSortOption: MultipleChoiceValueSortOption;
    stockCarsMultipleChoiceValueSortOption: MultipleChoiceValueSortOption;
    labels: Record<CarFilterLabelType, string>;
    usedCarsDisableSavedCars: boolean;
    stockCarsDisableSavedCars: boolean;
    // UC-406 - enables an additional property in the query to get the total count of cars.
    usedCarsEnableExperimentalTotalCountQuery: boolean;
    stockCarsEnableExperimentalTotalCountQuery: boolean;
    usedCarsEnablePrivatelySoldVehicles: boolean;
    stockCarsEnablePrivatelySoldVehicles: boolean;
    usedCarsEnableUcProgramBadges: boolean;
    stockCarsEnableUcProgramBadges: boolean;
    ucProgramCustomerPromiseBanner: UscCustomerPromiseBannerType;
    nonUcProgramCustomerPromiseBanner: UscCustomerPromiseBannerType;
    privatelySoldCustomerPromiseBanner: UscCustomerPromiseBannerType;
    usedCarsEnableVehicleAggregations: boolean;
    stockCarsEnableVehicleAggregations: boolean;
    usedCarsVehicleAggregationsVersionCode: string;
    stockCarsVehicleAggregationsVersionCode: string;
    usedCarsFinanceUseBoldText: boolean;
    stockCarsFinanceUseBoldText: boolean;
};

export const carFilterSettingsInitialState: CarFilterSettingsType = {
    initialized: false,
    useGlobalStore: false,
    uscEnv: UscEnv.Production,
    distributorCode: "",
    stockCarDropdownSpecConfig: [
        {
            type: UsedCarDropdownSpec.FuelType,
            title: "Fuel Type",
            tooltip: "",
        },
        {
            type: UsedCarDropdownSpec.Transmission,
            title: "Transmission",
            tooltip: "",
        },
        {
            type: UsedCarDropdownSpec.Doors,
            title: "Doors",
            tooltip: "",
        },
        {
            type: UsedCarDropdownSpec.PowerOutput,
            title: "Power Output",
            tooltip: "",
        },
    ],
    stockCarsShowLocation: false,
    usedCarsShowLocation: false,
    usedCarDropdownSpecConfig: [
        {
            type: UsedCarDropdownSpec.Year,
            title: "Year",
            tooltip: "",
        },
        {
            type: UsedCarDropdownSpec.Mileage,
            title: "Mileage",
            tooltip: "",
        },
        {
            type: UsedCarDropdownSpec.FuelType,
            title: "Fuel Type",
            tooltip: "",
        },
        {
            type: UsedCarDropdownSpec.Transmission,
            title: "Transmission",
            tooltip: "",
        },
        {
            type: UsedCarDropdownSpec.Warranty,
            title: "Warranty",
            tooltip: "",
        },
        {
            type: UsedCarDropdownSpec.EcoLabel,
            title: "Eco Label",
            tooltip: "",
        },
        {
            type: UsedCarDropdownSpec.Doors,
            title: "Doors",
            tooltip: "",
        },
        {
            type: UsedCarDropdownSpec.PowerOutput,
            title: "Power Output",
            tooltip: "",
        },
    ],
    usedCarResultAspectRatio: "16:9",
    stockCarResultAspectRatio: "16:9",
    usedCarResultObjectFit: "cover",
    stockCarResultObjectFit: "cover",
    usedCarEnableZeroMileage: true,
    usedCarsWarrantyFilterConfig: {
        showAnyWarranty: false,
        showTerms: true,
    },
    usedCarSimilarCarsConfig: [
        { filterId: SimilarCarFilterId.Model },
        { filterId: SimilarCarFilterId.Brand },
        { filterId: SimilarCarFilterId.FuelType },
        { filterId: SimilarCarFilterId.Transmission },
        { filterId: SimilarCarFilterId.Year, value: "2" },
        { filterId: SimilarCarFilterId.Price, value: "20" },
        { filterId: SimilarCarFilterId.Mileage, value: "20" },
    ],
    stockCarSimilarCarsConfig: [
        { filterId: SimilarCarFilterId.Model },
        { filterId: SimilarCarFilterId.Brand },
        { filterId: SimilarCarFilterId.FuelType },
        { filterId: SimilarCarFilterId.Transmission },
        { filterId: SimilarCarFilterId.Price, value: "20" },
    ],
    // The finance will be enabled when the usedCarsEnableFinance/stockCarsEnableFinance setting + the FM enableFinance is set to true
    // The usedCarsEnableFinance/stockCarsEnableFinance settings are currently mostly used for enabling the finance easier on the author environment
    // We set these settings default to true, so the default behaviour is that the FM determines whether the finance is shown or not
    usedCarsEnableFinance: true,
    stockCarsEnableFinance: true,
    usedCarsEnableStatusBadge: false,
    usedCarsEnableBiasedSort: false,
    stockCarsEnableStatusBadge: false,
    urlUsedCarsDetailPage: "",
    urlUsedCarsContactPage: "",
    urlUsedCarsLandingPage: "",
    urlStockCarsLandingPage: "",
    urlStockCarsDetailPage: "",
    urlStockCarsContactPage: "",
    dealerRangeValues: [10, 20, 30],
    contentBlockConfigurations: [],
    usedCarMainFilters: [UsedCarFilterId.Brand, UsedCarFilterId.Model, UsedCarFilterId.Price, UsedCarFilterId.Location],
    stockCarMainFilters: [
        StockCarFilterId.Brand,
        StockCarFilterId.Model,
        StockCarFilterId.Price,
        StockCarFilterId.Location,
    ],
    stockCarDisableCarCategory: false,
    usedCarDisableCarCategory: false,
    usedCardisableMonthInRegistrationSpec: false,
    stockCardisableMonthInRegistrationSpec: false,
    comingSoonBlockConfigurations: [],
    usedCarLegalSpecConfig: {},
    stockCarLegalSpecConfig: {},
    usedCarShowZeroCo2SpecValues: false,
    stockCarShowZeroCo2SpecValues: false,
    usedCarsHideTopFilters: false,
    stockCarsHideTopFilters: false,
    usedCarsHideZeroResultCountFilters: false,
    stockCarsHideZeroResultCountFilters: false,
    usedCarsLocalContactDealerFormUrl: "",
    stockCarsLocalContactDealerFormUrl: "",
    topFiltersBackgroundImageUrl: "",
    usedCarsEnableDynamicPriceFilter: false,
    stockCarsEnableDynamicPriceFilter: false,
    usedCarsSortOptions: [DfCarSortOrder.Published],
    stockCarsSortOptions: [DfCarSortOrder.Published],
    usedCarsMultipleChoiceValueSortOption: MultipleChoiceValueSortOption.Label,
    stockCarsMultipleChoiceValueSortOption: MultipleChoiceValueSortOption.Label,
    labels: { ...carFilterLabels },
    usedCarsDisableSavedCars: false,
    stockCarsDisableSavedCars: false,
    usedCarsEnableExperimentalTotalCountQuery: false,
    stockCarsEnableExperimentalTotalCountQuery: false,
    usedCarsEnablePrivatelySoldVehicles: false,
    stockCarsEnablePrivatelySoldVehicles: false,
    usedCarsEnableUcProgramBadges: false,
    stockCarsEnableUcProgramBadges: false,
    ucProgramCustomerPromiseBanner: {
        bannerItems: [],
        title: "",
    },
    nonUcProgramCustomerPromiseBanner: {
        bannerItems: [],
        title: "",
    },
    privatelySoldCustomerPromiseBanner: {
        bannerItems: [],
        title: "",
    },
    usedCarsEnableVehicleAggregations: false,
    stockCarsEnableVehicleAggregations: false,
    usedCarsVehicleAggregationsVersionCode: "",
    stockCarsVehicleAggregationsVersionCode: "",
    usedCarsFinanceUseBoldText: false,
    stockCarsFinanceUseBoldText: false,
};

const CarFilterSettingsReducer = (
    state: CarFilterSettingsType = carFilterSettingsInitialState,
    action: CarFilterSettingsActionsType,
): CarFilterSettingsType => {
    switch (action.type) {
        case CAR_FILTER_SETTINGS_INIT: {
            return { ...state, ...action.initState, initialized: true };
        }

        case CAR_FILTER_SETTINGS_SET_USE_GLOBAL_STORE: {
            return { ...state, useGlobalStore: action.useGlobalStore };
        }

        case CAR_FILTER_SETTINGS_OVERRIDE: {
            return { ...state, ...action.override };
        }

        default:
            return state;
    }
};

export default CarFilterSettingsReducer;
