import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { CarFilterSettingsType } from "../redux/reducers/CarFilterSettingsReducer";
import { DEFAULT_RESULT_COUNT, CAR_FILTER_RESULTS_RESULT_COUNT } from "./constants/filterConstants";
import { UsedCarResultType, UscContext } from "../../shared-logic/types/UscCommonTypes";
import { CarFiltersReducerType } from "../redux/reducers/CarFiltersReducer";
import { COMPONENT_CAR_FILTER_RESULTS, COMPONENT_USED_STOCK_CARS } from "../../../shared-logic/server/components";
import { carFilterValuesToParams } from "./params";
import { UscVehicleUrlInfo } from "../../shared-logic/utils/uscUtils";
import { getLocationFilterId } from "./filters";
import { formatNumber } from "../../../common-deprecated/Globalize";
import { DealerResultType } from "../../../common-deprecated/utils/dealerConstants";
import { CarFilterLabelType } from "./constants/labels";
import { getRetailerAppsQueryString } from "../../../common-deprecated/utils";

export const getCarFilterUrl = (
    commonSettings: CommonSettingsType,
    carFilterSettings: CarFilterSettingsType,
    carFilters: CarFiltersReducerType,
): string => {
    const { resourcePath, country, language, isStandalone } = commonSettings;
    const { currentFilter } = carFilters;
    const { urlStockCarsLandingPage, urlUsedCarsLandingPage } = carFilterSettings;

    const filterParams = carFilterValuesToParams(carFilters, currentFilter);
    const retailerQueryString = getRetailerAppsQueryString(commonSettings);
    const brandParam = commonSettings.query.brand ? `brand=${commonSettings.query.brand}` : "";

    const queryParamString = Object.entries(filterParams)
        .map(([key, value]) => `${key}=${value}`)
        .concat(isStandalone ? [] : ["scrollToCarFilter=true"])
        .concat(retailerQueryString ? [retailerQueryString] : [])
        .concat(isStandalone && brandParam ? [brandParam] : [])
        .join("&");

    const baseStandaloneUrl = `${resourcePath}/${country}/${language}/car-filter?carFilter=${currentFilter}`;
    const baseIntegratedUrl = currentFilter === UscContext.Used ? urlUsedCarsLandingPage : urlStockCarsLandingPage;

    return isStandalone
        ? `${baseStandaloneUrl}${queryParamString ? `&${queryParamString}` : ""}`
        : `${baseIntegratedUrl}${queryParamString ? `?${queryParamString}` : ""}`;
};

/**
 * Simplified version of getCarFilterUrl, specifically for URLs we use in the
 * PaginationControls component (a hrefs on the page number buttons).
 * To support canonicalization on integrated environments, page should be the only parameter in the URL.
 */
export const getPaginationUrl = (
    commonSettings: CommonSettingsType,
    carFilterSettings: CarFilterSettingsType,
    carFilters: CarFiltersReducerType,
    page: number,
): string => {
    const { resourcePath, country, language, isStandalone } = commonSettings;
    const { currentFilter } = carFilters;
    const { urlStockCarsLandingPage, urlUsedCarsLandingPage } = carFilterSettings;

    const queryParamString = page > 1 ? `page=${page}` : "";
    const standaloneUrl = `${resourcePath}/${country}/${language}/car-filter?carFilter=${carFilters.currentFilter}${
        queryParamString ? `&${queryParamString}` : ""
    }`;
    const integratedUrl = `${
        currentFilter === UscContext.Used ? urlUsedCarsLandingPage : urlStockCarsLandingPage
    }?${queryParamString}`;

    return isStandalone ? standaloneUrl : integratedUrl;
};

/**
 * Used for limiting the amount of results the car filter results component displays
 */
export const getTotalResultCount = (
    commonSettings: CommonSettingsType,
    carFilters: CarFiltersReducerType,
    ignoreVPBlock: boolean = false,
): number => {
    const { pagination } = carFilters;

    if (
        commonSettings.component === COMPONENT_CAR_FILTER_RESULTS ||
        commonSettings.component === COMPONENT_USED_STOCK_CARS
    ) {
        return CAR_FILTER_RESULTS_RESULT_COUNT;
    }

    if (pagination.page === 1 && !ignoreVPBlock) return DEFAULT_RESULT_COUNT - 1; // -1 to account for ValueProposition block

    return DEFAULT_RESULT_COUNT;
};

export const getUscVehicleUrlInfoFromResult = (vehicle: UsedCarResultType): UscVehicleUrlInfo => ({
    model: vehicle.product.model,
    transmissionType: vehicle.product.transmission.transmissionType?.description || "",
    bodyType: vehicle.product.bodyType,
    manufacturer: vehicle.product.brand.description,
    vehicleForSaleId: vehicle.id,
    marketingFuelType: vehicle.product.engine.marketingFuelType.description,
    firstRegistrationDate: vehicle.history?.registrationDate || "",
    productionDate: vehicle.productionDate,
});

/**
 * Get the altTag for an image of a UsedCarResult
 */
export const getUsedCarResultImageAltTag = (result: UsedCarResultType): string => {
    const { dealer, product, history } = result;
    const { brand, model, engine, grade, doors, carType } = product;
    const year = history?.registrationDate ? new Date(history.registrationDate).getFullYear() : "";

    return `${year} ${brand.description} ${model as string} ${(grade?.gradeDescription as string) || ""} - ${
        dealer?.name || ""
    } - ${carType.description as string} ${doors} ${(engine.description as string) || ""}`;
};

/**
 *  Returns a formatted dealer distance if available.
 *  Even though the car result object also has a dealer object we need to retrieve the dealer distance from the dealerCache.
 *  DealerCache is updated through the location filter and contains a list of dealers with actual ranges compared to the static dealer object of a car result.
 */
export const getDealerDistance = (
    dealer: DealerResultType | null,
    carFilters: CarFiltersReducerType,
    commonSettings: CommonSettingsType,
): string => {
    if (!dealer) return "";

    const locationFilterId = getLocationFilterId(carFilters.currentFilter);

    const foundDealer = carFilters[locationFilterId].dealerCache.find((dealerItem) => dealerItem.id === dealer.id);
    return foundDealer ? formatNumber(foundDealer?.distance, commonSettings.culture.name, "n1") : "";
};

/**
 * returns the correct pageDisclaimerLabel based on the carFilterContext
 */
export const getPageDisclaimerLabel = (currentFilter: UscContext): CarFilterLabelType => {
    switch (currentFilter) {
        case UscContext.Used:
            return "carFilterPageDisclaimerUsed";
        case UscContext.Stock:
            return "carFilterPageDisclaimerStock";
    }
};

/**
 * Returns the correct CarFilter result label based on the total result count
 */
export const getResultsLabel = (resultCount: number): CarFilterLabelType => {
    let seeResultsLabelKey: CarFilterLabelType = "carFilterSeeResults";
    if (resultCount === 1) seeResultsLabelKey = "carFilterSeeResult";
    if (resultCount === 0) seeResultsLabelKey = "carFilterNoResults";
    return seeResultsLabelKey;
};

/**
 * In the past, we used to calculate the total page count by dividing the total results
 * by the given result count (11 on page 1, 12 on all other pages). This resulted in incorrect
 * behaviour when there are 12 results in total (or the resultCount is divisible by 2)
 * the last page would show an empty result list (dividing 12 by 12, resulting in there supposedly being only one page).
 * To improve this, we now calculate the total page count by dividing the total results by the default result count (11).
 * If there are more results than the default result count, we add additional pages to the total page count.
 */
export const calculateTotalPageCount = (totalResults: number, hasContentBlock?: boolean): number => {
    const remainingResults = Math.max(0, totalResults - (DEFAULT_RESULT_COUNT - (hasContentBlock ? 1 : 0)));
    const additionalPages = Math.ceil(remainingResults / DEFAULT_RESULT_COUNT);
    return 1 + additionalPages;
};
