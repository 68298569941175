/**
 * Initialize the Map of available components.
 */

export const COMPONENT_BUILDANDBUY = "buildandbuy" as const;
export const COMPONENT_TRADEIN_AEM = "tradein-aem" as const;
export const COMPONENT_TRADEIN_AEM_PDF = "tradein-aem-pdf" as const;
export const COMPONENT_PDF = "pdf" as const;
export const COMPONENT_GRADE_EXPLORER = "grade-explorer" as const;
export const COMPONENT_COMPARE_V2 = "compare-v2" as const;
export const COMPONENT_COMPARE_V2_PDF = "compare-v2/pdf" as const;
export const COMPONENT_COMPARE_USC = "compare-usc" as const;
export const COMPONENT_COMPARE_USC_PDF = "compare-v2/usedCarPdf" as const;
export const COMPONENT_EQUIPMENT = "equipment" as const;
export const COMPONENT_EQUIPMENT_STANDARD = "equipment-standard" as const;
export const COMPONENT_EQUIPMENT_OPTIONAL = "equipment-optional" as const;
export const COMPONENT_CAR_FILTER = "car-filter" as const;
export const COMPONENT_CAR_FILTER_HEADER = "car-filter-header" as const;
export const COMPONENT_CAR_FILTER_RESULTS = "car-filter-results" as const;
export const COMPONENT_CAR_FILTER_TESTGROUND = "car-filter-testground" as const;
export const COMPONENT_USED_STOCK_CARS = "used-stock-cars" as const;
export const COMPONENT_USED_STOCK_CARS_PDF = "used-stock-cars-pdf" as const;
export const COMPONENT_USED_STOCK_CARS_FORM = "used-stock-cars-form" as const;
export const COMPONENT_MODEL_FILTER = "model-filter" as const;
export const COMPONENT_MODEL_FILTER_RESULTS = "model-filter-results" as const;
export const COMPONENT_LEASING_FILTER = "leasing-filter" as const;
export const COMPONENT_LEASING_HEADER = "leasing-header" as const;
export const COMPONENT_LEASING_TESTGROUND = "leasing-testground" as const;
export const COMPONENT_LEASING_DETAILS = "leasing-details" as const;
export const COMPONENT_PRICE_LIST_GRADES = "price-list-grades" as const;
export const COMPONENT_PRICE_LIST_GRADES_PDF = "price-list-grades-pdf" as const;
export const COMPONENT_PRICE_LIST_DETAILS = "price-list-details" as const;
export const COMPONENT_PRICE_LIST_DETAILS_PDF = "price-list-details-pdf" as const;
export const COMPONENT_PRICE_LIST_OVERVIEW = "price-list-overview" as const;
export const COMPONENT_GRADE_SELECTOR = "grade-selector" as const;
export const COMPONENT_CAR_CONFIG = "car-config" as const;
export const COMPONENT_CROSS_MODEL_COMPARE = "cross-model-compare" as const;
export const COMPONENT_CROSS_MODEL_COMPARE_PDF = "cross-model-compare-pdf" as const;

export type PdfComponentType =
    | typeof COMPONENT_TRADEIN_AEM_PDF
    | typeof COMPONENT_COMPARE_V2_PDF
    | typeof COMPONENT_COMPARE_USC_PDF
    | typeof COMPONENT_PDF
    | typeof COMPONENT_USED_STOCK_CARS_PDF
    | typeof COMPONENT_PRICE_LIST_GRADES_PDF
    | typeof COMPONENT_PRICE_LIST_DETAILS_PDF
    | typeof COMPONENT_CROSS_MODEL_COMPARE_PDF;

export type ComponentType =
    | typeof COMPONENT_BUILDANDBUY
    | typeof COMPONENT_TRADEIN_AEM
    | typeof COMPONENT_GRADE_EXPLORER
    | typeof COMPONENT_COMPARE_V2
    | typeof COMPONENT_COMPARE_USC
    | typeof COMPONENT_EQUIPMENT
    | typeof COMPONENT_EQUIPMENT_STANDARD
    | typeof COMPONENT_EQUIPMENT_OPTIONAL
    | typeof COMPONENT_CAR_FILTER
    | typeof COMPONENT_CAR_FILTER_HEADER
    | typeof COMPONENT_CAR_FILTER_RESULTS
    | typeof COMPONENT_CAR_FILTER_TESTGROUND
    | typeof COMPONENT_USED_STOCK_CARS
    | typeof COMPONENT_USED_STOCK_CARS_FORM
    | typeof COMPONENT_MODEL_FILTER
    | typeof COMPONENT_MODEL_FILTER_RESULTS
    | typeof COMPONENT_LEASING_FILTER
    | typeof COMPONENT_LEASING_HEADER
    | typeof COMPONENT_LEASING_TESTGROUND
    | typeof COMPONENT_LEASING_DETAILS
    | typeof COMPONENT_PRICE_LIST_GRADES
    | typeof COMPONENT_PRICE_LIST_DETAILS
    | typeof COMPONENT_PRICE_LIST_OVERVIEW
    | typeof COMPONENT_GRADE_SELECTOR
    | typeof COMPONENT_CAR_CONFIG
    | typeof COMPONENT_CROSS_MODEL_COMPARE
    | PdfComponentType;

/**
 * Maps a ComponentType to it's standalone URL.
 * Mainly useful for getting the standalone url for older components as all newer ones reuse the componentType as path.
 * For this reason this is only used in server v1.
 */
export const ENDPOINT_COMPONENT_MAP: Record<ComponentType, string> = {
    [COMPONENT_BUILDANDBUY]: "build-and-buy",
    [COMPONENT_TRADEIN_AEM]: COMPONENT_TRADEIN_AEM,
    [COMPONENT_TRADEIN_AEM_PDF]: COMPONENT_TRADEIN_AEM_PDF,
    [COMPONENT_GRADE_EXPLORER]: COMPONENT_GRADE_EXPLORER,
    [COMPONENT_COMPARE_V2]: COMPONENT_COMPARE_V2,
    [COMPONENT_COMPARE_USC]: COMPONENT_COMPARE_USC,
    [COMPONENT_COMPARE_V2_PDF]: COMPONENT_COMPARE_V2_PDF,
    [COMPONENT_COMPARE_USC_PDF]: COMPONENT_COMPARE_USC_PDF,
    [COMPONENT_EQUIPMENT]: COMPONENT_EQUIPMENT,
    [COMPONENT_EQUIPMENT_STANDARD]: COMPONENT_EQUIPMENT_STANDARD,
    [COMPONENT_EQUIPMENT_OPTIONAL]: COMPONENT_EQUIPMENT_OPTIONAL,
    [COMPONENT_PDF]: COMPONENT_PDF,
    [COMPONENT_CAR_FILTER]: COMPONENT_CAR_FILTER,
    [COMPONENT_CAR_FILTER_HEADER]: COMPONENT_CAR_FILTER_HEADER,
    [COMPONENT_CAR_FILTER_RESULTS]: COMPONENT_CAR_FILTER_RESULTS,
    [COMPONENT_CAR_FILTER_TESTGROUND]: COMPONENT_CAR_FILTER_TESTGROUND,
    [COMPONENT_USED_STOCK_CARS]: COMPONENT_USED_STOCK_CARS,
    [COMPONENT_USED_STOCK_CARS_PDF]: COMPONENT_USED_STOCK_CARS_PDF,
    [COMPONENT_USED_STOCK_CARS_FORM]: COMPONENT_USED_STOCK_CARS_FORM,
    [COMPONENT_MODEL_FILTER]: COMPONENT_MODEL_FILTER,
    [COMPONENT_MODEL_FILTER_RESULTS]: COMPONENT_MODEL_FILTER_RESULTS,
    [COMPONENT_LEASING_FILTER]: COMPONENT_LEASING_FILTER,
    [COMPONENT_LEASING_HEADER]: COMPONENT_LEASING_HEADER,
    [COMPONENT_LEASING_TESTGROUND]: COMPONENT_LEASING_TESTGROUND,
    [COMPONENT_LEASING_DETAILS]: COMPONENT_LEASING_DETAILS,
    [COMPONENT_PRICE_LIST_GRADES]: COMPONENT_PRICE_LIST_GRADES,
    [COMPONENT_PRICE_LIST_GRADES_PDF]: COMPONENT_PRICE_LIST_GRADES_PDF,
    [COMPONENT_PRICE_LIST_DETAILS]: COMPONENT_PRICE_LIST_DETAILS,
    [COMPONENT_PRICE_LIST_DETAILS_PDF]: COMPONENT_PRICE_LIST_DETAILS_PDF,
    [COMPONENT_PRICE_LIST_OVERVIEW]: COMPONENT_PRICE_LIST_OVERVIEW,
    [COMPONENT_GRADE_SELECTOR]: COMPONENT_GRADE_SELECTOR,
    [COMPONENT_CAR_CONFIG]: COMPONENT_CAR_CONFIG,
    [COMPONENT_CROSS_MODEL_COMPARE]: COMPONENT_CROSS_MODEL_COMPARE,
    [COMPONENT_CROSS_MODEL_COMPARE_PDF]: COMPONENT_CROSS_MODEL_COMPARE_PDF,
};
