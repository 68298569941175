import React from "react";
import { useCarFilterSelector } from "../../../../redux/store";
import { CarFilterModalType } from "../../../../utils/modalConstants";
import { ModalViewType } from "../../../../../../common-deprecated/types/CommonTypes";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import FilterSavedCars from "../../filters/FilterSavedCars";
import ActiveFilterSliderContainer from "../../active-filters/slider/ActiveFilterSliderContainer";
import FilterList from "../../filters/FilterList";
import ModalBaseView from "../../../../../shared-components/toyota/styles/organisms/Modal";
import { IconClose } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import * as ModalStyles from "../../../../../shared-components/toyota/styles/organisms/Modal.styles";
import * as Styles from "./CarFiltersModal.styles";
import ButtonPrimary from "../../../../../../common-deprecated/components/aem/toyota/ButtonPrimary";
import { getResultsLabel } from "../../../../utils/helpers";

const CarFiltersModal = (props: ModalViewType<CarFilterModalType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const resultCount = useCarFilterSelector((state) => state.carResults.totalResultCount);
    const seeResultsLabel = useCarFilterLabel(getResultsLabel(resultCount), { value: resultCount });
    const titleLabel = useCarFilterLabel("carFilterAllFilters");

    return (
        <ModalBaseView modalIdType={modalSettings.type} show={show} onClose={close} size="medium">
            <ModalStyles.Header>
                <Styles.Title>{titleLabel}</Styles.Title>
                <ModalStyles.Button type="button" onClick={close}>
                    <IconClose />
                </ModalStyles.Button>
            </ModalStyles.Header>
            <ModalStyles.Body noSpacing>
                <Styles.FilterSavedCarsWrapper>
                    <FilterSavedCars />
                </Styles.FilterSavedCarsWrapper>
                <Styles.FilterActiveFiltersWrapper>
                    <ActiveFilterSliderContainer />
                </Styles.FilterActiveFiltersWrapper>
                <FilterList />
                <Styles.ModalFooter>
                    <ButtonPrimary color="primaryRed" fullWidth onClick={close}>
                        {seeResultsLabel}
                    </ButtonPrimary>
                </Styles.ModalFooter>
            </ModalStyles.Body>
        </ModalBaseView>
    );
};

export default CarFiltersModal;
