import styled from "styled-components";

export const Title = styled.h2`
    margin: 0;
    padding: 0;
    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: none;
    color: var(--color-primary-black);
`;
