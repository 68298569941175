import {
    LoadedUsedCompareCarType,
    LoadingUsedCompareCarType,
    UsedCarCompareType,
} from "../../utils/usedCarCompareTypes";

export const USED_CAR_COMPARE_ADD_CARS = "usedCarCompare/addCars" as const;
export const USED_CAR_COMPARE_SET_PARSED_CARS = "usedCarCompare/setParsedCars" as const;
export const USED_CAR_COMPARE_REMOVE_CARS = "usedCarCompare/removeMultipleCars" as const;
export const USED_CAR_COMPARE_CLEAR_ALL = "usedCarCompare/clearAll" as const;

export type UsedCarCompareAddCarsType = {
    type: typeof USED_CAR_COMPARE_ADD_CARS;
    cars: LoadingUsedCompareCarType[];
    index: number;
};

export const usedCompareAddCars = (
    cars: Omit<LoadingUsedCompareCarType, "loading">[],
    index: number,
): UsedCarCompareAddCarsType => ({
    type: USED_CAR_COMPARE_ADD_CARS,
    cars: cars.map((car) => ({ ...car, loading: true })),
    index,
});
export type UsedCarCompareSetParsedCarsType = {
    type: typeof USED_CAR_COMPARE_SET_PARSED_CARS;
    cars: LoadedUsedCompareCarType[];
    index: number;
};

export const usedCompareSetParsedCars = (
    cars: UsedCarCompareType[],
    index: number,
): UsedCarCompareSetParsedCarsType => ({
    type: USED_CAR_COMPARE_SET_PARSED_CARS,
    cars: cars.map((car) => ({ ...car, loading: false })),
    index,
});

export type UsedCarCompareRemoveCarsType = {
    type: typeof USED_CAR_COMPARE_REMOVE_CARS;
    index: number[];
};
export const usedCompareRemoveCars = (index: number[]): UsedCarCompareRemoveCarsType => ({
    type: USED_CAR_COMPARE_REMOVE_CARS,
    index,
});

export type UsedCarCompareClearAllType = { type: typeof USED_CAR_COMPARE_CLEAR_ALL };
export const usedCompareClearAll = (): UsedCarCompareClearAllType => ({ type: USED_CAR_COMPARE_CLEAR_ALL });

export type UsedCarCompareExternalDataActionsType =
    | UsedCarCompareAddCarsType
    | UsedCarCompareSetParsedCarsType
    | UsedCarCompareRemoveCarsType
    | UsedCarCompareClearAllType;
