/* eslint-disable no-bitwise, no-console */

const VERBOSE_QUIET = 0;
const VERBOSE_DEBUG = 1;
const VERBOSE_INFO = 2;
const VERBOSE_WARNING = 4;
const VERBOSE_ERROR = 8;

export const E_ERROR = VERBOSE_ERROR;
export const E_WARNING = VERBOSE_WARNING | E_ERROR;
export const E_INFO = VERBOSE_INFO | E_WARNING;
export const E_ALL = VERBOSE_DEBUG | E_INFO;
export const E_NONE = VERBOSE_QUIET;

// AWS doesnt like multiline logs, so ideally when on AWS we only log stringified JSON which gets formatted properly in CloudWatch.
// To achieve this, do a very hacky check to determine if we are server side and on a non-localhost machine.
export const isAWS = !!(process?.env?.API_ENDPOINT && !process.env.API_ENDPOINT.includes("localhost"));

// Basic helper to properly log exceptions and other items.
const parseContextAWS = (context: any): string => {
    // Errors dont stringify properly, use toString instead.
    if (context instanceof Error) return context.toString();
    if (typeof context === "string") return context;

    return context ? JSON.stringify(context) : "";
};

class Debug {
    level: number;

    constructor() {
        this.level = E_ALL;
    }

    getDate(): string {
        const date = new Date();

        return isAWS
            ? // Full date/time logs for hosted/production
              new Date().toISOString()
            : // Simplified time-only logs for local/dev
              `${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}` +
                  `:${String(date.getSeconds()).padStart(2, "0")}` +
                  `.${String(date.getMilliseconds()).padStart(3, "0")}`;
    }

    is(level: number) {
        return (this.level & level) === level;
    }

    quiet(): void {
        this.level = E_NONE;
    }

    debug(message: string, context: any = "", force = false) {
        if (this.is(VERBOSE_DEBUG) || force) {
            console.log.apply(console, ["DEBUG", this.getDate(), message, isAWS ? parseContextAWS(context) : context]);
        }
    }

    info(message: string, context: any = "", force = false) {
        if (this.is(VERBOSE_INFO) || force) {
            console.log.apply(console, ["INFO", this.getDate(), message, isAWS ? parseContextAWS(context) : context]);
        }
    }

    warn(message: string, context: any = "", force = false) {
        if (this.is(VERBOSE_WARNING) || force) {
            console.warn.apply(console, [
                "WARNING",
                this.getDate(),
                message,
                isAWS ? JSON.stringify(context) : context,
            ]);
        }
    }

    error(message: string, context: any = "", force = false) {
        if (this.is(VERBOSE_ERROR) || force) {
            console.error.apply(console, [
                "ERROR",
                this.getDate(),
                message,
                isAWS ? parseContextAWS(context) : context,
            ]);
        }
    }
}

export default new Debug();
