import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { CarFilterDispatchType, useCarFilterSelector } from "../../../../redux/store";
import { SupportedSaveContexts } from "../../../../utils/localStorage";
import { addSavedCar, removeSavedCar } from "../../../../redux/actions/CarFiltersActions";
import * as Styles from "./styles/UsedCarResultStyles";
import { IconWrapper } from "../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { UsedCarResultType } from "../../../../../shared-logic/types/UscCommonTypes";
import { Ellipsis } from "../../../../../../common-deprecated/styles/v2/toyota/utilities/Ellipsis";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import { IconHeart, IconHeartFilled } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { enabledSavedCarsSelector } from "../../../../utils/selectorUtils";
import { trackAddFavouriteClick } from "../../../../utils/tracking";

type SaveButton = { result: UsedCarResultType; isSimilarCar?: boolean };
const SaveButton = (props: SaveButton): JSX.Element | null => {
    const { result, isSimilarCar } = props;
    const dispatch = useDispatch<CarFilterDispatchType>();
    const currentFilter = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const isSaved = useCarFilterSelector(({ carFilters }) =>
        carFilters.savedCars[currentFilter as SupportedSaveContexts].includes(result.id),
    );
    const savedCarsEnabled = useCarFilterSelector(enabledSavedCarsSelector, shallowEqual);
    const [saveLabel, savedLabel] = useCarFilterLabel(["carFilterSaveCar", "carFilterSavedCar"]);

    if (!savedCarsEnabled) return null;

    const saveClick = (): void => {
        if (isSaved) {
            dispatch(removeSavedCar(currentFilter as SupportedSaveContexts, result.id));
        } else {
            dispatch(addSavedCar(currentFilter as SupportedSaveContexts, result.id));
            trackAddFavouriteClick(currentFilter, result, isSimilarCar);
        }
    };

    return (
        <Styles.Button
            type="button"
            isActive={isSaved}
            onClick={(evt) => {
                evt.stopPropagation();
                saveClick();
            }}
        >
            <IconWrapper>{isSaved ? <IconHeartFilled /> : <IconHeart />}</IconWrapper>
            <Ellipsis maxWidth="100%">{isSaved ? savedLabel : saveLabel}</Ellipsis>
        </Styles.Button>
    );
};

export default SaveButton;
