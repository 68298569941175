export const UscColorProps = {
    "primary-white": "--color-primary-white",
    "primary-black": "--color-primary-black",
    "highlight-black": "--color-highlight-black",
    "primary-red": "--color-primary-red",
    "highlight-red": "--color-highlight-red",
    green: "--color-green",
    "grey-1": "--color-grey-1",
    "grey-4": "--color-grey-4",
    "grey-6": "--color-grey-6",
    "grey-7": "--color-grey-7",
    outline: "--color-blue",
    indigo: "--color-indigo",
    bz4x: "--color-bz4x",
    "bz4x-hover": "--color-bz4x-hover",
    "eco-a": "--color-eco-a",
    "eco-b": "--color-eco-b",
    "eco-c": "--color-eco-c",
    "eco-d": "--color-eco-d",
    "eco-e": "--color-eco-e",
    "eco-f": "--color-eco-f",
    "eco-g": "--color-eco-g",
    "eco-default": "--color-eco-default",
};

export const UscSpacingProps = {
    "spacing-0": "--spacing-0",
    "spacing-1": "--spacing-1",
    "spacing-2": "--spacing-2",
    "spacing-3": "--spacing-3",
    "spacing-4": "--spacing-4",
    "spacing-5": "--spacing-5",
    "spacing-6": "--spacing-6",
    "spacing-7": "--spacing-7",
    "spacing-8": "--spacing-8",
    "spacing-9": "--spacing-9",
    "spacing-10": "--spacing-10",
    "spacing-11": "--spacing-11",
};

export type UscColorType = keyof typeof UscColorProps;
export type UscSpacingType = keyof typeof UscSpacingProps;
