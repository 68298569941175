import React, { PropsWithChildren, useState } from "react";
import useModalBaseView from "../../../../../common-deprecated/hooks/useModalBaseView";
import { MODAL_DIALOG_ID } from "../../../../../common-deprecated/utils";
import { useCommonSelector } from "../../../../../common-deprecated/redux/commonStore";
import { useUniqueId } from "../../../../../common-deprecated/hooks";
import { ModalIdsType } from "../../../../../common-deprecated/types/CommonTypes";
import * as Styles from "./Modal.styles";

export type CarFilterModalBaseViewType = {
    modalIdType: ModalIdsType;
    show: boolean;
    disableOutsideClick?: boolean;
    noscroll?: boolean;
    dialogLabelId?: string;
    dialogDescId?: string;
    size?:
        | "small"
        | "medium"
        | "large"
        | "fullwidth"
        | "fullscreen"
        | "external"
        | "fillwindow"
        | "fullwidthautoheight"
        | "fitcontent"
        | "fitcontent-height";
    onClose: () => void;
    onAnimationEnd?: () => void;
};

const ModalBaseView: React.FC<PropsWithChildren<CarFilterModalBaseViewType>> = (props) => {
    const { modalIdType, show, disableOutsideClick, noscroll, size, dialogLabelId, dialogDescId, children } = props;
    const { onClose, onAnimationEnd } = props;
    const [transitionEnd, setTransitionEnd] = useState(false);
    const modalRef = useModalBaseView({ onClose, disableOutsideClick, show, modalIdType });
    const isAuthor = useCommonSelector((state) => state.commonSettings.query.authorPreview || false);
    const uniqueId = useUniqueId();

    return (
        <Styles.Backdrop isAuthor={isAuthor} active={show} noscroll={noscroll}>
            <Styles.Dialog
                id={MODAL_DIALOG_ID + uniqueId}
                ref={modalRef}
                size={size}
                role="dialog"
                aria-labelledby={dialogLabelId}
                aria-describedby={dialogDescId}
                tabIndex={0}
                active={show}
                transitionEnd={transitionEnd}
                onTransitionEnd={() => setTransitionEnd(true)}
                onAnimationEnd={onAnimationEnd}
            >
                {children}
            </Styles.Dialog>
        </Styles.Backdrop>
    );
};

export default ModalBaseView;
