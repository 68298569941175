import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const GroupedButtons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
`;

export const RadioWrapper = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

export const Title = styled.h2`
    margin-bottom: 1rem;
`;

export const Input = styled.input`
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border: 1px solid ${theme.colors.border};
    border-radius: 0.25rem;
`;

export const Radio = styled.input`
    margin-right: 0.5rem;

    &::before {
        position: absolute;
        top: 3px;
        width: 20px;
        height: 20px;
        content: "";
        border: 2px solid ${theme.colors.grey1};
        border-radius: 50%;
    }

    &::after {
        display: ${({ checked }) => (checked ? "block" : "none")};
        position: absolute;
        top: 10px;
        width: 6px;
        height: 6px;
        content: "";
        background-color: ${theme.colors.primaryRed};
        border-radius: 50%;
    }
`;

export const Label = styled.label`
    display: inline-block;
    margin-right: 1rem;
`;
