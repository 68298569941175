import React, { memo } from "react";
import * as Styles from "./Preloader.styles";

type PreloaderProps = {
    delayedVisibility?: boolean;
    size?: number;
    hasLabel?: boolean;
};

const PreLoader: React.FC<PreloaderProps> = (props) => {
    const { delayedVisibility, size, hasLabel } = props;
    return <Styles.Preloader delayedVisibility={delayedVisibility} size={size} hasLabel={hasLabel} />;
};

export default memo(PreLoader);
