// Reducers can be found at FinanceRateReducer and InsuranceRateReducer.
import { PSLSDataType } from "../../../../shared-logic/types/PSLSDataType";
import { ParsedInsuranceRateType } from "../../../../shared-logic/types/FinanceInsuranceRateTypes";
import { FetchAndParseJsonType, UpdateQuoteConfigType } from "../../../../../common-deprecated/types/CommonTypes";
import { FinanceInsuranceCalculatorType } from "../../utils/FinanceInsuranceCalculatorLink";
import { ParsedMonthlyRateType } from "../../../../../common-deprecated/types/FinanceInsuranceRateTypes";
import { FinanceUpdateTrigger } from "../../../../../common-deprecated/features/tradein/utils/tradeInAemUtils";
import { BusinessLeasingActionsType } from "./BusinessFinanceActions";

export const INIT_FINANCE_QUOTES = "monthlyRate/initQuotes" as const;
export const START_FINANCE_RATE_FETCH = "monthlyRate/startFinanceRateFetch" as const;
export const FINISH_FINANCE_RATE_FETCH = "monthlyRate/finishFinanceRateFetch" as const;
export const START_INSURANCE_RATE_FETCH = "monthlyRate/startInsuranceRateFetch" as const;
export const FINISH_INSURANCE_RATE_FETCH = "monthlyRate/finishInsuranceRateFetch" as const;
export const UPDATE_QUOTE_CONFIG = "monthlyRate/updateQuoteConfig" as const;
export const FQS_INITIALIZED = "monthlyRate/fqsInitialized" as const;
export const CLEAR_INSURANCE_RATE = "monthlyRate/clearInsuranceRate" as const;
export const CLEAR_FINANCE_RATE = "monthlyRate/clearFinanceRate" as const;
export const SET_INSURANCE_PERSONALIZED = "monthlyRate/setInsurancePersonalized" as const;
export const START_PSLS_FETCH = "psls/startFetch" as const;
export const FINISH_PSLS_FETCH = "psls/finishFetch" as const;
export const PREPARE_CALCULATOR = "calculator/prepare" as const;
export const RESET_CALCULATOR = "calculator/reset" as const;
export const RESET_FINANCE_RATES = "monthlyRate/resetFinanceRates" as const;
export const SET_FINANCE_RATE_FETCH_LOADING = "monthlyRate/setFinanceRateFetchLoading" as const;
export const SET_FINANCE_COLLAPSABLE_ACTIVE = "collapsable/finance" as const;
export const SET_INSURANCE_COLLAPSABLE_ACTIVE = "collapsable/insurance" as const;
export const SET_FINANCE_PRODUCT_TYPE = "monthlyRate/setFinanceProductType" as const;

type InitFinanceQuotesType = { type: typeof INIT_FINANCE_QUOTES; financeQuote: string; insuranceQuote: string };
type PslsParentActionType = PrepareCalculatorType | StartFinanceRateFetchType | StartInsuranceRateFetchType;
export type StartFinanceRateFetchType = {
    type: typeof START_FINANCE_RATE_FETCH;
    pslsLoaded?: boolean;
    trigger: FinanceUpdateTrigger;
    fetchAndParseJson?: FetchAndParseJsonType;
};
export type FinishFinanceRateFetchType = {
    type: typeof FINISH_FINANCE_RATE_FETCH;
    result: ParsedMonthlyRateType;
    rates?: { [index: string]: string };
    trigger: FinanceUpdateTrigger;
    previousDownPayment?: number;
};
export type ClearFinanceRateActionType = {
    type: typeof CLEAR_FINANCE_RATE;
};
export type StartInsuranceRateFetchType = {
    type: typeof START_INSURANCE_RATE_FETCH;
    fetchAndParseJson?: FetchAndParseJsonType;
    pslsLoaded?: boolean;
};
export type FinishInsuranceRateFetchType = {
    type: typeof FINISH_INSURANCE_RATE_FETCH;
    result: ParsedInsuranceRateType;
};
export type UpdateQuoteConfigActionType = {
    type: typeof UPDATE_QUOTE_CONFIG;
    data?: UpdateQuoteConfigType;
    loadExternalPriceFunction?: boolean;
    quotes?: { finance: string | null; insurance: string | null };
    updateFinanceRates?: boolean;
    updateInsuranceRates?: boolean;
};
export type FqsInitializedActionType = {
    type: typeof FQS_INITIALIZED;
};
export type ClearInsuranceRateActionType = {
    type: typeof CLEAR_INSURANCE_RATE;
};
export type SetInsurancePersonalizedActionType = {
    type: typeof SET_INSURANCE_PERSONALIZED;
    isPersonalized: boolean;
};
export type StartPSLSFetchType = {
    type: typeof START_PSLS_FETCH;
    parentAction?: PslsParentActionType;
    fetchAndParseJson?: FetchAndParseJsonType;
};
export type FinishPSLSFetchType = {
    type: typeof FINISH_PSLS_FETCH;
    data: PSLSDataType | null;
    error?: boolean;
    parentAction?: PslsParentActionType;
};
export type PrepareCalculatorType = {
    type: typeof PREPARE_CALCULATOR;
    executeSave: boolean;
    loadPSLS: boolean;
    pslsLoaded?: boolean;
};
export type ResetCalculatorType = { type: typeof RESET_CALCULATOR; calculatorType: FinanceInsuranceCalculatorType };
export type ResetFinanceRatesType = { type: typeof RESET_FINANCE_RATES };
export type SetFinanceRateFetchLoadingType = { type: typeof SET_FINANCE_RATE_FETCH_LOADING; loading: boolean };
export type SetFinanceCollapsableActiveType = { type: typeof SET_FINANCE_COLLAPSABLE_ACTIVE; active: boolean };
export type SetInsuranceCollapsableActiveType = { type: typeof SET_INSURANCE_COLLAPSABLE_ACTIVE; active: boolean };
export type SetFinanceProductType = { type: typeof SET_FINANCE_PRODUCT_TYPE; financeProductType: string };

export const initQuotes = (financeQuote: string = "", insuranceQuote: string = ""): InitFinanceQuotesType => ({
    type: INIT_FINANCE_QUOTES,
    financeQuote,
    insuranceQuote,
});
export const startPSLSFetch = (
    parentAction: PslsParentActionType,
    fetchAndParseJson?: FetchAndParseJsonType,
): StartPSLSFetchType => ({
    type: START_PSLS_FETCH,
    parentAction,
    fetchAndParseJson,
});
export const finishPSLSFetch = (
    data: PSLSDataType | null,
    error: boolean = false,
    parentAction?: PslsParentActionType,
): FinishPSLSFetchType => ({ type: FINISH_PSLS_FETCH, data, error, parentAction });

export const startFinanceRateFetch = (
    trigger: FinanceUpdateTrigger,
    fetchAndParseJson?: FetchAndParseJsonType,
): StartFinanceRateFetchType => ({
    type: START_FINANCE_RATE_FETCH,
    trigger,
    fetchAndParseJson,
});
export const finishFinanceRateFetch = (
    result: ParsedMonthlyRateType,
    trigger: FinanceUpdateTrigger,
): FinishFinanceRateFetchType => ({
    type: FINISH_FINANCE_RATE_FETCH,
    result,
    trigger,
});
export const startInsuranceRateFetch = (fetchAndParseJson?: FetchAndParseJsonType): StartInsuranceRateFetchType => ({
    type: START_INSURANCE_RATE_FETCH,
    fetchAndParseJson,
});
export const finishInsuranceRateFetch = (result: ParsedInsuranceRateType): FinishInsuranceRateFetchType => ({
    type: FINISH_INSURANCE_RATE_FETCH,
    result,
});

export const resetFinanceRates = (): ResetFinanceRatesType => ({ type: RESET_FINANCE_RATES });

export const setInsurancePersonalized = (isPersonalized: boolean): SetInsurancePersonalizedActionType => ({
    type: SET_INSURANCE_PERSONALIZED,
    isPersonalized,
});

export const clearInsuranceRate = (): ClearInsuranceRateActionType => ({
    type: CLEAR_INSURANCE_RATE,
});

export const clearFinanceRate = (): ClearFinanceRateActionType => ({
    type: CLEAR_FINANCE_RATE,
});

export const updateQuoteConfig = (
    data?: UpdateQuoteConfigType,
    loadExternalPriceFunction?: boolean,
): UpdateQuoteConfigActionType => ({
    type: UPDATE_QUOTE_CONFIG,
    data,
    loadExternalPriceFunction,
});

export const fqsInitialized = (): FqsInitializedActionType => ({ type: FQS_INITIALIZED });

export const prepareCalculator = (
    executeSave: boolean,
    loadPSLS: boolean,
    pslsLoaded?: boolean,
): PrepareCalculatorType => ({ type: PREPARE_CALCULATOR, executeSave, loadPSLS, pslsLoaded });
export const resetCalculator = (calculatorType: FinanceInsuranceCalculatorType): ResetCalculatorType => ({
    type: RESET_CALCULATOR,
    calculatorType,
});

export const setFinanceRateFetchLoading = (loading: boolean): SetFinanceRateFetchLoadingType => ({
    type: SET_FINANCE_RATE_FETCH_LOADING,
    loading,
});

export const setFinanceCollapsable = (active: boolean): SetFinanceCollapsableActiveType => ({
    type: SET_FINANCE_COLLAPSABLE_ACTIVE,
    active,
});
export const setInsuranceCollapsable = (active: boolean): SetInsuranceCollapsableActiveType => ({
    type: SET_INSURANCE_COLLAPSABLE_ACTIVE,
    active,
});
export const setFinanceProductType = (financeProductType: string): SetFinanceProductType => ({
    type: SET_FINANCE_PRODUCT_TYPE,
    financeProductType,
});

export type FinanceInsuranceActionsType =
    | StartFinanceRateFetchType
    | FinishFinanceRateFetchType
    | StartInsuranceRateFetchType
    | FinishInsuranceRateFetchType
    | StartPSLSFetchType
    | FinishPSLSFetchType
    | UpdateQuoteConfigActionType
    | FqsInitializedActionType
    | ClearInsuranceRateActionType
    | ClearFinanceRateActionType
    | SetInsurancePersonalizedActionType
    | PrepareCalculatorType
    | ResetCalculatorType
    | ResetFinanceRatesType
    | SetFinanceRateFetchLoadingType
    | InitFinanceQuotesType
    | SetInsuranceCollapsableActiveType
    | SetFinanceCollapsableActiveType
    | SetFinanceProductType
    | BusinessLeasingActionsType;
