/**
 * Wrapper for History, to make it possible for server side rendering.
 * Functions based on https://github.com/ReactTraining/history
 */
import { History, LocationDescriptorObject, LocationListener, Path, UnregisterCallback } from "history";
import createHistory from "history/createBrowserHistory";
import * as DOMUtils from "history/DOMUtils";
import { objectToQueryString, queryStringToObject } from "./utils";
import { QueryType } from "./server/types";
import { UscHistoryStateType } from "../used-stock-cars/pdp/utils/types";

/**
 * Constants used for History functions.
 */
const history = DOMUtils.canUseDOM ? createHistory() : false;

export const HISTORY_TYPE_PROP = "orHistoryType" as const;

export enum HistoryType {
    Build = "build",
    Filter = "filter",
    FinanceOption = "financeOption",
    Tradein = "tradein",
    CarFilter = "carFilter",
    Usc = "usc",
    Carconfig = "carconfig",
}

/**
 * Type definitions.
 * Query and callback types based on https://github.com/ReactTraining/history implementation, check this for specifics.
 */

// History listen callback.
export type ListenCallbackType = (location: Location, action: string) => void;

// Add additional state types to this type when necessary.
export type HistoryStateType = UscHistoryStateType;

/**
 * Listen to a history event.
 */
export const listen = (callback: LocationListener): UnregisterCallback | null => {
    if (!history) return null;
    return history.listen(callback);
};

/**
 * Push history. Do not use this function outside of this file scope, use pushState instead.
 */
export const push = (path: Path | LocationDescriptorObject): void => {
    if (!history) return;
    history.push(path);
};

/**
 * Push OR history state. This will add the history type prop which is used to determine if the popped history is pushed by OR.
 */
export const pushState = (state: HistoryStateType, orHistoryType: HistoryType): void => {
    // Also keep the hash/search because GFI still uses old-fashioned hash for navigation. Query strings also need to be propagated.
    push({
        state: { ...state, [HISTORY_TYPE_PROP]: orHistoryType },
        hash: window.location.hash,
        search: window.location.search,
    });
};

/**
 * Get the current history state object.
 */
export const getState = (): { [index: string]: unknown } | null => {
    // @ts-ignore
    return history && history.state ? history.state.state : undefined;
};

/**
 * Check if the given history state is equal to the current history state.
 */
export const historyStateIsEqual = (state: HistoryStateType, orHistoryType: HistoryType): boolean => {
    const currentState = getState();
    if (!currentState || currentState[HISTORY_TYPE_PROP] !== orHistoryType) return false;

    return !!Object.keys(currentState)
        .filter((key) => key === HISTORY_TYPE_PROP)
        // @ts-ignore
        .find((key) => currentState[key] !== state[key]);
};

/**
 * Push an object to history state as query parameters.
 */
export const pushQueryParams = (query: { [key in keyof QueryType]: unknown }, orHistoryType: HistoryType): void => {
    push({
        search: `?${objectToQueryString(query)}`,
        state: { [HISTORY_TYPE_PROP]: orHistoryType },
        hash: window.location.hash,
    });
};

/**
 * Replaces given query params in the URL. Set as "null" to remove the query param.
 *
 * @param queries - key/value object of the query and its to be replaced value.
 */
export const replaceQueryParams = (queries: { [key in keyof QueryType]: string | number | null | boolean }): void => {
    const query = queryStringToObject(window.location.search);

    (Object.keys(queries) as (keyof QueryType)[]).forEach((queryKey) => {
        if (queries[queryKey] === null && typeof query[queryKey] !== "undefined") delete query[queryKey];
        else if (queries[queryKey]) query[queryKey] = queries[queryKey];
    });

    (history as History).replace({ search: `?${objectToQueryString(query)}` });
};

/**
 * Updates the financeOption query parameter in state.
 */
export const updateFinanceQueryParam = (value: "cash" | "monthly"): void => {
    const query = queryStringToObject(window.location.search);
    query.financeOption = value;
    // @ts-ignore
    pushQueryParams(query, HistoryType.FinanceOption);
};

/**
 * Check if history api is available.
 */
export const historyIsEnabled = (): boolean => !!history;
