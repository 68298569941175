export type CoordinateType = { lat: number; lon: number };

export type DayCodeType = "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT" | "SUN";

export enum DealerBrand {
    Toyota = "Toyota",
    Lexus = "Lexus",
}

export type OpeningHoursType = {
    start: DayCodeType;
    end?: DayCodeType;
    openingHours: { from: string; to: string }[];
};

// Dealer operations are usually used to filter dealers based on the application (trade-in, used cars, stock cars).
export enum DealerOperation {
    DealerTool = "DealerTool",
    FastFit = "FastFit",
    OnlineRetailing = "OnlineRetailing",
    OnlineServiceBooking = "OnlineServiceBooking",
    PartsShop = "PartsShop",
    ShowRoom = "ShowRoom",
    ToyotaRentCars = "ToyotaRentCars",
    TradeInAppraisal = "Trade In Appraisal",
    UsedCars = "UsedCars",
    UsedCarsDelivery = "UsedCarsDelivery",
    WorkShop = "WorkShop",
    YarisGRMN = "YarisGRMN",
    UsedCarOnlineFinancingPanE = "UsedCarOnlineFinancingPanE",
    UsedCarOnlineReservationPanE = "UsedCarOnlineReservationPanE",
}

// Used to distinguish between dealer requests coming from
// USC/TI as these use different formatting logic
export enum A2DContext {
    USC = "USC", // Used Stock Cars
    TI = "TI", // Trade In
}

export type DealerResultType = {
    id: string;
    localId: string;
    name: string;
    address: {
        line1: string;
        line2: string;
        city: string;
        zip: string;
        region: string;
        province: string;
        country: string;
    };
    geoLocation: CoordinateType;
    distance: number;
    phoneNumber: string;
    email: string;
    website: string;
    impressum: string;
    openingHours: OpeningHoursType[];
    operations: DealerOperation[];
    outletNmsc: {
        uclAuthorizationNumber: string;
    };
    status: string;
};

export type DealerGroupResultType = {
    id: string;
    name: string;
    dealerIds: string[];
    status: string;
};

export type DealerHoldingResultType = {
    id: string;
    name: string;
    dealerIds: string[];
    dealerGroupIds: string[];
    status: string;
};

export const defaultDealerResult: DealerResultType = {
    id: "",
    localId: "",
    name: "",
    address: {
        line1: "",
        line2: "",
        zip: "",
        city: "",
        region: "",
        province: "",
        country: "",
    },
    geoLocation: { lat: 0, lon: 0 },
    distance: 0,
    phoneNumber: "",
    email: "",
    website: "",
    impressum: "",
    openingHours: [],
    operations: [],
    outletNmsc: {
        uclAuthorizationNumber: "",
    },
    status: "",
};

export const defaultDealerGroupResult: DealerGroupResultType = {
    id: "",
    name: "",
    dealerIds: [],
    status: "",
};

export const defaultDealerHoldingResult: DealerHoldingResultType = {
    id: "",
    name: "",
    dealerIds: [],
    dealerGroupIds: [],
    status: "",
};
