import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { ModalViewType } from "../../../../../../common-deprecated/types/CommonTypes";
import { ContactDealerType } from "../../../../../shared-logic/utils/integrated-modal/integratedModalConstants";
import { UscContext as uscContextType } from "../../../../../shared-logic/types/UscCommonTypes";
import ModalBaseView from "../../../styles/organisms/Modal";
import Text from "../../../styles/atoms/Text";
import Button from "../../../styles/atoms/Button";
import Icon from "../../../styles/atoms/Icon";
import PreLoader from "../../../styles/molecules/Preloader";
import * as PreLoaderStyles from "../../../styles/molecules/Preloader.styles";
import * as ModalStyles from "../../../styles/organisms/Modal.styles";
// Async loading of UscForm.
const IntegratedUscForm = loadable(async () => {
    const component = await import(
        /* webpackChunkName: "integrated-usc-form-toyota" */
        "../../../../../used-stock-cars-form/components/toyota/IntegratedUscForm"
    );
    return component.default;
});

// This modal is not actually used in UscForm itself, but is used to embed the UscForm modal into Compare/USC component.
const IntegratedUscFormModal = (props: ModalViewType<ContactDealerType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { vehicleForSaleId, externalData, vehicle, dealer, uscContext, modalTitle } = modalSettings;

    const [showDealerForm, setShowDealerForm] = useState<boolean>(false);
    const [dealerFormInitializing, setDealerFormInitializing] = useState<boolean>(true);

    // Show the dealer form on first render
    useEffect(() => {
        setShowDealerForm(true);
    }, []);

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={close} show={show} size="medium">
            <ModalStyles.Header noBorder>
                <Text tag="h2" variant="h4">
                    {modalTitle}
                </Text>
                <Button variant="control" onClick={close} size="small">
                    <Icon variant="close" />
                </Button>
            </ModalStyles.Header>
            <ModalStyles.Body>
                {showDealerForm && (
                    <ModalStyles.Content>
                        {dealerFormInitializing && (
                            <PreLoaderStyles.LoadingWrapper height="100%">
                                <PreLoader size={40} />
                            </PreLoaderStyles.LoadingWrapper>
                        )}
                        <IntegratedUscForm
                            onInitFinish={() => setDealerFormInitializing(false)}
                            vehicleForSaleId={vehicleForSaleId}
                            context={uscContext as uscContextType}
                            externalData={externalData}
                            vehicle={vehicle}
                            dealer={dealer}
                        />
                    </ModalStyles.Content>
                )}
            </ModalStyles.Body>
        </ModalBaseView>
    );
};

export default IntegratedUscFormModal;
