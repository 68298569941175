import React, { useMemo, useRef } from "react";
import * as Styles from "./styles/UsedCarResultStyles";
import * as BadgeStyles from "./styles/BadgeStyles";
import * as ResultImageSliderStyles from "../image-slider/styles/ResultImageSliderStyles";
import ResultPlaceholderImage from "../image-slider/ResultPlaceholderImage";
import { useCarFilterSelector } from "../../../../redux/store";
import { UscContext } from "../../../../../shared-logic/types/UscCommonTypes";

type UsedCarResultPlaceholderPropsType = {
    isCarFilterResults?: boolean;
};

/**
 * "Loading" version of the UsedCarResult component.
 *
 * This component should match the default height of a UsedCarResult:
 * - One line cash price
 * - One line monthly price
 * - Car title + description
 */
const UsedCarResultPlaceholder = ({ isCarFilterResults }: UsedCarResultPlaceholderPropsType): JSX.Element => {
    const elementRef = useRef<HTMLDivElement>(null);
    const aspectRatio = useCarFilterSelector((state) =>
        state.carFilters.currentFilter === UscContext.Used
            ? state.carFilterSettings.usedCarResultAspectRatio
            : state.carFilterSettings.stockCarResultAspectRatio,
    );
    const objectFit = useCarFilterSelector((state) =>
        state.carFilters.currentFilter === UscContext.Used
            ? state.carFilterSettings.usedCarResultObjectFit
            : state.carFilterSettings.stockCarResultObjectFit,
    );

    // Never rerender this component as this should never change.
    return useMemo(
        () => (
            <Styles.Wrapper
                ref={elementRef}
                isCarFilterResults={isCarFilterResults}
                data-testid="used-car-result-placeholder"
            >
                <Styles.ImageWrapper>
                    <BadgeStyles.Group>
                        <BadgeStyles.Badge>
                            <Styles.LoadBar width={20} height={20} />
                        </BadgeStyles.Badge>
                    </BadgeStyles.Group>

                    <ResultImageSliderStyles.SliderViewport
                        objectFit={objectFit}
                        hasAspectRatio43={aspectRatio === "4:3"}
                    >
                        <ResultPlaceholderImage />
                    </ResultImageSliderStyles.SliderViewport>
                </Styles.ImageWrapper>

                <Styles.Header>
                    <Styles.Title>
                        <Styles.LoadBar width={200} height={20} />
                    </Styles.Title>
                    <Styles.SubTitle>
                        <Styles.LoadBar width={200} height={20} />
                    </Styles.SubTitle>
                    <Styles.Location>
                        <Styles.LoadBar width={200} height={20} />
                    </Styles.Location>
                    <Styles.LoadBar width={200} height={20} />
                </Styles.Header>

                <div>
                    <Styles.Specs isCarFilterResults={isCarFilterResults}>
                        <Styles.SpecsList>
                            <li>
                                <Styles.LoadBar width={100} height={20} />
                            </li>
                            <li>
                                <Styles.LoadBar width={100} height={20} />
                            </li>
                            <li>
                                <Styles.LoadBar width={100} height={20} />
                            </li>
                            <li>
                                <Styles.LoadBar width={100} height={20} />
                            </li>
                        </Styles.SpecsList>
                    </Styles.Specs>
                </div>

                <Styles.Prices isCarFilterResults={isCarFilterResults}>
                    <Styles.LoadBar width={200} height={20} />
                </Styles.Prices>

                <Styles.CtaGroup isCarFilterResults={isCarFilterResults}>
                    <Styles.LoadBar width="100%" height={48} />
                    <Styles.LoadBar width="100%" height={48} />
                </Styles.CtaGroup>
                <Styles.DeliveryAndButtonGroup>
                    <Styles.DeliveryBadgeWrapper>
                        <Styles.LoadBar width={200} height={20} />
                    </Styles.DeliveryBadgeWrapper>
                    <Styles.ButtonGroup isCarFilterResults={isCarFilterResults}>
                        <Styles.Button>
                            <Styles.LoadBar width={50} height={20} />
                        </Styles.Button>
                        <Styles.Button>
                            <Styles.LoadBar width={50} height={20} />
                        </Styles.Button>
                    </Styles.ButtonGroup>
                </Styles.DeliveryAndButtonGroup>
            </Styles.Wrapper>
        ),
        [],
    );
};

export default UsedCarResultPlaceholder;
