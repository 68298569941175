import styled, { css } from "styled-components";
import { ButtonIconAlignment, ButtonSizeType, ButtonVariantsType } from "./Button";
import { breakpoints } from "../../../../shared-logic/themes/toyotaTheme";

// STYLES
const baseButtonStyle = css<{ size?: ButtonSizeType; iconAlignment?: ButtonIconAlignment }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(var(--spacing-3) / 2) var(--spacing-3);
    font-size: 1.6rem;
    line-height: 2.2rem;

    ${({ size }) =>
        size === "fullwidth" &&
        css`
            width: 100%;
        `}

    ${({ iconAlignment }) =>
        iconAlignment === "right" &&
        css`
            flex-direction: row-reverse;
        `};

    &[disabled] {
        background-color: var(--color-grey-4);
    }
`;

const primary = css`
    --_button-bg-color: var(--button-bg-color, var(--color-highlight-red));
    --_button-bg-hover-color: var(--color-bg-hover-color, var(--color-primary-black));
    ${baseButtonStyle};
    color: var(--color-primary-white);
    background-color: var(--_button-bg-color);
    border: 1px solid transparent;

    &:is(:hover, :focus) {
        color: var(--color-primary-white);
        background-color: var(--_button-bg-hover-color);
        opacity: 0.8;
    }

    &:active {
        background-color: var(--color-highlight-black);
        opacity: 1;
    }
`;

const primaryDark = css`
    --button-bg-color: var(--color-primary-black);
    --button-bg-hover-color: var(--color-highlight-black);
    ${primary};
`;

const outline = css`
    ${baseButtonStyle};
    color: var(--color-primary-dark);
    background-color: transparent;
    border: 1px solid var(--color-primary-black);

    &:is(:hover, :focus) {
        color: var(--color-primary-white);
        background-color: var(--color-primary-black);
    }

    &:active {
        box-shadow: 0 0 0 1px var(--color-primary-black);
    }
`;

const cta = css`
    display: flex;
    flex: 1 0 0;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    column-gap: var(--spacing-2);
    padding: calc(var(--spacing-1) / 2) var(--spacing-1);
    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--color-primary-black);
    background-color: var(--color-primary-white);
    border: 1px solid var(--color-grey-6);

    & > div {
        display: flex;
        align-items: center;
        column-gap: var(--spacing-1);
    }

    svg,
    .dxp-icon {
        flex-shrink: 0;
    }

    &:is(:hover, :focus) {
        border-color: var(--color-primary-black);
    }

    &[disabled] {
        color: var(--color-grey-7);
        border-color: var(--color-grey-4);
    }

    @supports not (padding-inline: 0) {
        svg,
        .dxp-icon {
            margin-right: var(--spacing-2);
        }
    }
`;

const basketCta = css<{ iconAlignment?: ButtonIconAlignment }>`
    display: flex;
    flex-grow: 1;
    align-items: center;
    column-gap: calc(var(--spacing-3) / 2);
    padding: var(--spacing-1) calc(var(--spacing-3) / 2);
    font-family: var(--font-regular);
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: start;
    color: var(--color-primary-black);
    background: var(--color-primary-white);
    border: 1px solid var(--color-grey-6);
    border-radius: 4px;

    svg,
    .dxp-icon {
        flex-shrink: 0;
    }

    &:is(:hover, :focus) {
        border-color: var(--color-primary-black);
    }

    &:active {
        box-shadow: 0 0 0 1px var(--color-primary-black);
    }

    &[disabled] {
        color: var(--color-grey-7);
        border-color: var(--color-grey-4);
    }

    ${({ iconAlignment }) =>
        iconAlignment === "right" &&
        css`
            flex-direction: row-reverse;
        `};

    @supports not (padding-inline: 0) {
        svg,
        .dpx-icon {
            margin-right: calc(var(--spacing-3) / 2);
        }
    }
`;

const ctaLarge = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: calc(var(--spacing-1) / 2) var(--spacing-2) calc(var(--spacing-1) / 2) var(--spacing-1);
    font-family: var(--font-book);
    font-size: 1.6rem;
    line-height: 2rem;
    color: var(--color-primary-black);
    background: var(--color-primary-white);
    border: 1px solid var(--color-grey-6);

    & > div {
        display: flex;
        align-items: center;
        column-gap: var(--spacing-1);
    }

    svg,
    .dxp-icon {
        flex-shrink: 0;
    }

    &:is(:hover, :focus) {
        border-color: var(--color-primary-black);
    }

    &:active {
        box-shadow: 0 0 0 1px var(--color-primary-black);
    }

    &[disabled] {
        color: var(--color-grey-7);
        border-color: var(--color-grey-4);
    }
`;

const iconText = css<{ iconAlignment?: ButtonIconAlignment; textUnderline?: boolean; size?: ButtonSizeType }>`
    display: inline-flex;
    column-gap: var(--spacing-1);
    align-items: center;
    justify-content: center;
    font-family: var(--font-book);
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--color-primary-black);

    &:is(:hover, :focus) {
        color: var(--color-grey-7);
    }

    &[disabled] {
        opacity: 0.3;
    }

    svg,
    .dxp-icon {
        flex-shrink: 0;
    }

    ${({ iconAlignment }) =>
        iconAlignment === "right" &&
        css`
            flex-direction: row-reverse;
        `};

    ${({ textUnderline }) =>
        textUnderline &&
        css`
            span {
                text-decoration: underline;
            }
        `};

    ${({ size }) =>
        size === "small" &&
        css`
            font-size: 1.3rem;
            line-height: 2rem;
        `}

    @supports not (padding-inline: 0) {
        svg,
        .dpx-icon {
            margin-right: var(--spacing-1);
        }
    }
`;

const link = css<{ size?: ButtonSizeType }>`
    display: flex;
    padding: 0 0 calc(var(--spacing-1) / 2);
    font-family: var(--font-book);
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    color: var(--color-primary-black);
    border-bottom: 1px solid var(--color-primary-black);

    &:is(:hover, :focus) {
        opacity: 0.5;
    }

    &[disabled] {
        opacity: 0.3;
    }

    ${(props) =>
        props.size === "small" &&
        css`
            font-size: 1.3rem;
            line-height: 2rem;
        `};
`;

const clean = css`
    all: unset;
`;

const control = css<{ size?: ButtonSizeType }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: var(--color-primary-white);
    background-color: var(--color-primary-black);
    border: 1px solid var(--color-primary-black);
    border-radius: 50%;
    transition: color 0.05s ease-in, border-color 0.05s ease-in, border-width 0.05s ease-in,
        background-color 0.05s ease-in;

    &:is(:hover, :focus) {
        background-color: var(--color-highlight-black);
        border-color: var(--color-primary-white);
        border-width: 2px;
    }

    &[disabled] {
        color: var(--color-primary-black);
        background-color: var(--color-primary-white);
        border-color: var(--color-grey-4);
        border-width: 1px;
    }

    @media screen and (min-width: ${breakpoints.xl}) {
        width: 40px;
        height: 40px;
    }

    ${(props) =>
        props.size === "small" &&
        css`
            width: 32px;
            height: 32px;
        `};
`;

const controlNegative = css<{ size?: ButtonSizeType }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: var(--color-primary-black);
    background-color: var(--color-primary-white);
    border: 1px solid var(--color-primary-white);
    border-radius: 50%;
    transition: border-color 0.05s ease-in, border-width 0.05s ease-in;

    &:is(:hover, :focus) {
        color: var(--color-primary-white);
        background-color: var(--color-primary-black);
        border-color: var(--color-primary-white);
        border-width: 2px;
    }

    &[disabled] {
        color: var(--color-primary-black);
        background-color: var(--color-primary-white);
        border-color: var(--color-grey-4);
        border-width: 1px;
    }

    @media screen and (min-width: ${breakpoints.xl}) {
        width: 40px;
        height: 40px;
    }

    ${(props) =>
        props.size === "small" &&
        css`
            width: 32px;
            height: 32px;
        `};
`;

// COMPONENTS
export const ButtonVariants = {
    primary,
    "primary-dark": primaryDark,
    outline,
    cta,
    "basket-cta": basketCta,
    "cta-large": ctaLarge,
    "icon-text": iconText,
    link,
    control,
    "control-negative": controlNegative,
    clean,
};

export const ButtonVariant = styled.button<{ variant: ButtonVariantsType; skeleton?: boolean }>`
    ${({ variant }) =>
        variant &&
        css`
            ${ButtonVariants[variant]};
        `};
`;

export default ButtonVariant;
