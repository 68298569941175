import styled from "styled-components";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as CheckboxStyles from "../../styles/molecules/Checkbox.styles";

export const Title = styled.h2`
    margin: 0;
    padding: 0;
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: none;
    color: ${theme.colors.primaryBlack};
`;

export const FilterSavedCarsWrapper = styled.div`
    ${CheckboxStyles.Indicator} {
        padding: ${theme.space[2]}px;
    }
`;

export const FilterActiveFiltersWrapper = styled.div`
    padding-inline: ${theme.space[2]}px;

    @supports not (padding-inline: 0) {
        padding: 0 ${theme.space[2]}px;
    }
`;

export const ModalFooter = styled.div`
    position: sticky;
    bottom: 0;
    padding: ${theme.space[2]}px;
    background-color: ${theme.colors.primaryWhite};
    border-top: 1px solid ${theme.colors.grey2};
`;
