import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { FullQuickSpecConfigType, QuickSpecConfigType } from "../../../shared-logic/utils/uscUtils";
import { UscContext, UsedCarResultType } from "../../../shared-logic/types/UscCommonTypes";
import { useCarFilterSelector } from "../../redux/store";
import { amendQuickSpecConfigs, getUscLegalSpecConfigs } from "../../../shared-logic/utils/uscSpecUtils";
import {
    carFilterQuickSpecLabelsSelector,
    carFilterSpecsParamsSettingsSelector,
    carFilterWeightedSpecsLabelSelector,
} from "../../utils/specUtils";
import { uscNedcWltpDisclaimersLabelSelector } from "../../../shared-logic/utils/uscEcoUtils";

type useUsedCarResultSpecsReturnType = {
    hasShowMore: boolean;
    requiredSpecs: FullQuickSpecConfigType[];
    allSpecs: FullQuickSpecConfigType[];
};

/**
 * Maximum number of specs to display when not in overlay. This is not the true maximum,
 * as legal specs are always displayed. Though in the case where legal specs < MAX_CARD_SPECS, the
 * remaining space is filled with normal specs up until the maximum defined below.
 */
const MAX_CARD_SPECS = 4;

const useUsedCarResultSpecs = (result: UsedCarResultType): useUsedCarResultSpecsReturnType => {
    const [requiredSpecs, setRequiredSpecs] = useState<FullQuickSpecConfigType[]>([]);
    const [allSpecs, setAllSpecs] = useState<FullQuickSpecConfigType[]>([]);

    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const settings = useCarFilterSelector(carFilterSpecsParamsSettingsSelector, shallowEqual);
    const nedcWltpDisclaimerLabels = useCarFilterSelector(uscNedcWltpDisclaimersLabelSelector, shallowEqual);
    const weightedSpecsLabels = useCarFilterSelector(carFilterWeightedSpecsLabelSelector, shallowEqual);
    const quickSpecLabels = useCarFilterSelector(carFilterQuickSpecLabelsSelector, shallowEqual);

    const quickSpecConfigs = useCarFilterSelector(
        ({ carFilters, carFilterSettings }) =>
            carFilters.currentFilter === UscContext.Used
                ? carFilterSettings.usedCarDropdownSpecConfig
                : carFilterSettings.stockCarDropdownSpecConfig,
        shallowEqual,
    );
    const legalSpecConfigs = useCarFilterSelector(
        ({ carFilters, carFilterSettings }) =>
            getUscLegalSpecConfigs(
                carFilters.currentFilter === UscContext.Used
                    ? carFilterSettings.usedCarLegalSpecConfig
                    : carFilterSettings.stockCarLegalSpecConfig,
                result.product.engine.marketingFuelType.code,
            ),
        shallowEqual,
    );

    useEffect(() => {
        const specParams = {
            commonSettings,
            settings,
            labels: { nedcWltpDisclaimerLabels, quickSpecLabels, weightedSpecsLabels },
        };

        const specs = amendQuickSpecConfigs(
            [...legalSpecConfigs, ...quickSpecConfigs] as QuickSpecConfigType[],
            specParams,
            result,
            result.dealer,
        );

        const legalSpecs = specs.filter(({ isLegal }) => isLegal);
        const quickSpecs = specs.filter(({ isLegal }) => !isLegal);

        // On the card, show all legal specs
        // If there's space, show normal specs up to the max
        if (legalSpecs.length < MAX_CARD_SPECS) {
            setRequiredSpecs([...legalSpecs, ...quickSpecs.slice(0, MAX_CARD_SPECS - legalSpecs.length)]);
        } else {
            setRequiredSpecs(legalSpecs); // Always show _all_ legal specs
        }
        setAllSpecs([...legalSpecs, ...quickSpecs]);
    }, [
        commonSettings,
        legalSpecConfigs,
        nedcWltpDisclaimerLabels,
        quickSpecConfigs,
        result,
        settings,
        weightedSpecsLabels,
        quickSpecLabels,
    ]);

    return { hasShowMore: requiredSpecs.length < allSpecs.length, requiredSpecs, allSpecs };
};

export default useUsedCarResultSpecs;
