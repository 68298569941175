import { getLabelComponent, LabelComponentType } from "../../../../common-deprecated/components/label/Label";
import {
    getLabelFn,
    GetLabelType,
    getOptionalLabelFn,
    GetOptionalLabelType,
    getUseLabel,
    getUseOptionalLabel,
    UseLabelType,
    UseOptionalLabelType,
} from "../../../../common-deprecated/hooks/useLabel";
import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { RetailerSettingsType } from "../redux/reducers/RetailerSettingsReducer";

export const retailerLabels = {
    retailerScreenNavBack: "Back",
    retailerScreenNavHome: "Home",
    retailerScreenNavLoadConfig: "Load your configuration",
    retailerScreenNavNeedHelp: "Need help?",
    retailerScreenYourCode: "Your code",
    retailerScreenYourCodeIntro:
        "Did you already configure your vehicle at home? Enter your unique code here, and continue the journey.",
    retailerScreenYourCodeLabel: "Enter code",
    retailerScreenYourCodeFieldLabel: "Code",
    retailerScreenEnterYourCode: "Enter your configuration code",
    retailerScreenLoadConfig: "Load configuration",
    retailerScreenQrCodeSubText: "This unique code lets you view and amend your saved cars at any Lexus Centre.",
    retailerScreenWhatsNext: "What's next?",
    retailerScreenQrCodeSteps:
        "Talk to your retailer for more information on purchase and financing options. Scan the QR code with your smartphone and view this car when and where it suits you",
};

// ----------------------------------------------------------------------
// Helpers
// ----------------------------------------------------------------------

type RetailerLabelStateType = { commonSettings: CommonSettingsType; retailerSettings: RetailerSettingsType };
export type RetailerLabelType = keyof typeof retailerLabels;
type RetailerLabelParamType = Partial<Record<"value", string | number>>;

export const useRetailerLabel = getUseLabel(
    (state: RetailerLabelStateType) => state.retailerSettings.labels,
    (state: RetailerLabelStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseLabelType<RetailerLabelType, RetailerLabelParamType>;

export const useRetailerOptionalLabel = getUseOptionalLabel(
    (state: RetailerLabelStateType) => state.retailerSettings.labels,
    (state: RetailerLabelStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseOptionalLabelType<RetailerLabelType, RetailerLabelParamType>;

export const getRetailerLabel = getLabelFn(
    (state: RetailerLabelStateType) => state.retailerSettings.labels,
    (state: RetailerLabelStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetLabelType<RetailerLabelStateType, RetailerLabelType, RetailerLabelParamType>;

export const getRetailerOptionalLabel = getOptionalLabelFn(
    (state: RetailerLabelStateType) => state.retailerSettings.labels,
    (state: RetailerLabelStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetOptionalLabelType<RetailerLabelStateType, RetailerLabelType, RetailerLabelParamType>;

export const RetailerLabel = getLabelComponent(useRetailerLabel) as LabelComponentType<
    RetailerLabelType,
    RetailerLabelParamType
>;
