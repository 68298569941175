import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import { ThemeProvider } from "styled-components";
import Globalize from "../../common-deprecated/Globalize";
import { CarFilterStateType } from "./redux/store";
import getTheme from "../../common-deprecated/themes/getTheme";
import { renderReact } from "../../common-deprecated/utils/clientRenderUtils";
import { ModalProvider } from "../../common-deprecated/containers/ModalProvider";
import StoreWrapper from "./StoreWrapper";
import { WindowDimensionsProvider } from "../../common-deprecated/containers/WindowDimensionsProvider";
import { removeLastSlashAndWhiteSpace } from "../../common-deprecated/utils";
import { CommonSettingsType } from "../../common-deprecated/settings/fetchCommonSettings";

export const getInit =
    (CarFilterWrapper: React.ElementType, waitFn?: (commonSettings: CommonSettingsType) => Promise<void>) =>
    async (target: string, props: unknown, initialState: CarFilterStateType): Promise<void> => {
        const { commonSettings, carFilterSettings } = initialState;
        const { country, culture, currencyMultiplier } = commonSettings;

        // eslint-disable-next-line camelcase
        __webpack_public_path__ = `${removeLastSlashAndWhiteSpace(commonSettings.resourcePath)}/`;

        Globalize(country, culture, currencyMultiplier);

        if (waitFn) await waitFn(commonSettings);

        const forceClientRender = carFilterSettings.useGlobalStore;

        renderReact(
            target,
            commonSettings,
            <ThemeProvider theme={getTheme(commonSettings)}>
                <StoreWrapper initialState={initialState}>
                    <WindowDimensionsProvider>
                        <ModalProvider animateClosing>
                            <CarFilterWrapper />
                        </ModalProvider>
                    </WindowDimensionsProvider>
                </StoreWrapper>
            </ThemeProvider>,
            forceClientRender,
        );
    };
