import { getLabelComponent, LabelComponentType } from "../components/label/Label";
import {
    getLabelFn,
    GetLabelType,
    getOptionalLabelFn,
    GetOptionalLabelType,
    getUseLabel,
    getUseOptionalLabel,
    UseLabelType,
} from "../hooks/useLabel";
import { CommonSettingsType } from "../settings/fetchCommonSettings";
import { T1Labels } from "./t1labels";

// ----------------------------------------------------------------------
// Accessibility labels
// ----------------------------------------------------------------------
export const accessibilityLabels = {
    ariaSliderMinValue: "min value: {value}",
    ariaSliderMaxValue: "max value: {value}",
    ariaSliderMinValueTitleLabel: "Slider {value} minimum",
    ariaSliderMaxValueTitleLabel: "Slider {value} maximum",
};

export const commonLabels = {
    ...T1Labels,
    ...accessibilityLabels,
    close: "Close",
    quotes: "Quotes",
    dimensions: "Dimensions",
    uscExclVatCashPriceFormat: "%s ex.&nbsp;VAT", // Used specifically in UCL components to render Ex VAT formatting. %s is used as this is also used for price replacements in the website.
    uscInclVatCashPriceFormat: "%s", // Used specifically in UCL components to render Incl VAT formatting. By default, this label should only render the price. Only when localised, should it render the inclVat label.
    uscExclVatCashPriceDisclaimer: "",
    uscInclVatCashPriceDisclaimer: "",
    uscCashPriceDisclaimer: "", // Generic cash price disclaimer
    uscAccessoryCashPriceDisclaimer: "",
    uscNedcDisclaimer: "",
    uscWltpDisclaimer: "",
    uscNedcNotAvailableDisclaimer: "",
    uscEfficiencyClassNotAvailableDisclaimer: "",
    uscDualCurrencyDisclaimer: "Dual currency rate: {value}",
    uscPageFinanceDisclaimer: "",
    uscPrivatelySold: "Privately sold",
    uscApproved: "Approved",
    uscNonApproved: "Non-approved",
    tyreInfoFalse: "",
    tyreInfoTrue: "",
    printPrintedOn: "Printed on",
    genericOrErrorTitle: "Oops, something went wrong and we couldn't process your request.",
    soldOut: "", // Sold out
    availableForOnlineSale: "", // Available online
    availableForOnlineLeasing: "", // Available for leasing
    uscManufacturerWarrantyKmLimit: "KM Limit: {value}",
    uscManufacturerWarrantyValidUntil: "Valid until: {value}",
    uscManufacturerWarrantyUnspecified: "Unspecified",
    uscManufacturerWarrantyNone: "None",
    uscManufacturerWarrantyExpired: "Expired",
    uscSpecLabelYes: "Yes",
    uscSpecLabelNo: "No",
};

export type CommonLabelType = keyof typeof commonLabels;
type CommonLabelParamType = Partial<Record<"productName" | "value", string | number>>;

export type CommonStateType = {
    commonSettings: CommonSettingsType;
};

export const getCommonLabel = getLabelFn(
    (state: CommonStateType) => state.commonSettings.labels,
    (state: CommonStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetLabelType<CommonStateType, CommonLabelType, CommonLabelParamType>;

export const getCommonOptionalLabel = getOptionalLabelFn(
    (state: CommonStateType) => state.commonSettings.labels,
    (state: CommonStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetOptionalLabelType<CommonStateType, CommonLabelType, CommonLabelParamType>;

export const useCommonLabel = getUseLabel(
    (state: CommonStateType) => state.commonSettings.labels,
    (state: CommonStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseLabelType<CommonLabelType, CommonLabelParamType>;

export const useCommonOptionalLabel = getUseOptionalLabel(
    (state: CommonStateType) => state.commonSettings.labels,
    (state: CommonStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseLabelType<CommonLabelType, CommonLabelParamType>;

export const CommonLabel = getLabelComponent(useCommonLabel) as LabelComponentType<
    CommonLabelType,
    CommonLabelParamType
>;
