import { UsedCarResultType, UscContext } from "../../../shared-logic/types/UscCommonTypes";
import { DEFAULT_RESULT_COUNT, DfCarSortOrder } from "../../utils/constants/filterConstants";

// ----------------------------------------------------------------------
// DF car actions
// ----------------------------------------------------------------------
export const SET_CAR_RESULTS = "carResults/set";
export const FETCH_CAR_RESULTS = "carResults/fetch";
export const DISABLE_CAR_RESULTS_PLACEHOLDERS = "carResults/disablePlaceholders";
export const SET_CAR_RESULT_PLACEHOLDERS = "carResults/setPlaceholders";
export const SET_USED_CAR_RESULTS_ORDER = "carResults/used/setOrder";
export const SET_NEW_CAR_RESULTS_ORDER = "carResults/new/setOrder";

export type SetCarResultsType = {
    type: typeof SET_CAR_RESULTS;
    context: UscContext;
    results: UsedCarResultType[];
    totalResultCount: number;
};
export type FetchCarResultsType = {
    type: typeof FETCH_CAR_RESULTS;
    context: UscContext;
    resultCount: number;
    offset: number;
    forPlaceholders: boolean;
    includeActiveAggregations: boolean;
};
export type SetCarPlaceholderType = {
    type: typeof SET_CAR_RESULT_PLACEHOLDERS;
    context: UscContext;
    count: number;
};
export type DisableCarPlaceholderType = {
    type: typeof DISABLE_CAR_RESULTS_PLACEHOLDERS;
    context: UscContext;
};
export type SetUsedCarResultsOrder = { type: typeof SET_USED_CAR_RESULTS_ORDER; order: DfCarSortOrder };

// Add the provided car results to the state.
export const setCarResults = (
    context: UscContext,
    results: UsedCarResultType[],
    totalResultCount: number,
): SetCarResultsType => ({
    type: SET_CAR_RESULTS,
    context,
    results,
    totalResultCount,
});

// Start retrieving car results based on the current filter setup. (actual fetching load should happen in logic)
// forPlaceholders is used when placeholders for the to be fetched cars should be visible.
export const fetchUsedCarResults = (
    context: UscContext,
    offset: number,
    resultCount: number = DEFAULT_RESULT_COUNT,
    forPlaceholders: boolean = false,
    includeActiveAggregations: boolean = false,
): FetchCarResultsType => ({
    type: FETCH_CAR_RESULTS,
    context,
    offset,
    resultCount,
    forPlaceholders,
    includeActiveAggregations,
});

// Clear all results and add the provided amount of placeholders to the state.
export const setCarPlaceholders = (context: UscContext, count: number): SetCarPlaceholderType => ({
    type: SET_CAR_RESULT_PLACEHOLDERS,
    context,
    count,
});

export const disableUsedCarResultsPlaceholders = (context: UscContext): DisableCarPlaceholderType => ({
    type: DISABLE_CAR_RESULTS_PLACEHOLDERS,
    context,
});

export const setUsedCarSortOrder = (order: DfCarSortOrder): SetUsedCarResultsOrder => ({
    type: SET_USED_CAR_RESULTS_ORDER,
    order,
});

// ----------------------------------------------------------------------
// Filter updates
// ----------------------------------------------------------------------
export const FILTER_CAR_RESULTS = "carResults/filter";
export const REPLACE_CAR_RESULTS = "carResults/replace";

export type FilterCarResultsType = {
    type: typeof FILTER_CAR_RESULTS;
    context: UscContext;
    noTracking: boolean;
    causedByMainFilter: boolean;
    resultCount: number;
};

export type ReplaceCarResultsType = {
    type: typeof REPLACE_CAR_RESULTS;
    context: UscContext;
    results: UsedCarResultType[];
    totalResultCount: number;
};

// Triggered whenever a filter update happens. Converts current results to placeholders and should trigger a refetch.
// causedByMainFilter param is used for tracking purposes.
export const filterCarResults = (
    context: UscContext,
    noTracking: boolean = false,
    causedByMainFilter: boolean = false,
    resultCount: number = DEFAULT_RESULT_COUNT,
): FilterCarResultsType => ({
    type: FILTER_CAR_RESULTS,
    context,
    noTracking,
    causedByMainFilter,
    resultCount,
});

// Replace all cars in state with the provided results.
export const replaceCarResults = (
    context: UscContext,
    results: UsedCarResultType[],
    totalResultCount: number,
): ReplaceCarResultsType => ({ type: REPLACE_CAR_RESULTS, context, results, totalResultCount });

export type CarResultsActionsType =
    | SetCarResultsType
    | FetchCarResultsType
    | SetCarPlaceholderType
    | DisableCarPlaceholderType
    | FilterCarResultsType
    | ReplaceCarResultsType
    | SetUsedCarResultsOrder;
