import styled, { css } from "styled-components";

const skeletonStyle = css`
    display: inline-block;
    width: 100%;
    height: 1.5em;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%
        ),
        var(--color-grey-4);
    background-repeat: repeat-y;
    background-position: -50px 0;
    background-size: 50px 500px;
    animation: shine 1.6s ease-in-out infinite;

    @keyframes shine {
        to {
            background-position: 140% 0, 0 0;
        }
    }
`;

export const Textload = styled.span<{ width?: number; height?: number }>`
    ${skeletonStyle};
    ${({ width }) =>
        width &&
        css`
            width: ${width}px;
        `};
    ${({ height }) =>
        height &&
        css`
            height: ${height}px;
        `};
`;

export const Imgload = styled.span<{ width?: number; height?: number }>`
    ${skeletonStyle};
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    ${({ width }) =>
        width &&
        css`
            width: ${width}px;
        `};
    ${({ height }) =>
        height &&
        css`
            height: ${height}px;
        `};
`;
