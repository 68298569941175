import { useCallback } from "react";
import { useDispatch } from "react-redux";
import Debug from "../../../common-deprecated/Debug";
import { hideModal, showModal, updateModalSettings } from "../../../common-deprecated/redux/actions/ModalActions";
import { CommonDispatchType, useCommonSelector } from "../../../common-deprecated/redux/commonStore";
import { DealerResultType } from "../../../common-deprecated/utils/dealerConstants";
import { UscContext } from "../types/UscCommonTypes";
import { UscVehicleType, getUscUrl, getUscVehicleUrlInfoFromVehicle, getLocalDealerFormData } from "../utils/uscUtils";
import { MODAL_USC_INJECTION } from "../utils/modalConstants";

const CONTAINER_ID = "usc-local-form-container";
const ACK_MESSAGE_TYPE = "or-vin-ack";
const VIN_MESSAGE_TYPE = "or-vin";
const VIN_URL_PARAM = "vin";

type UseLocalContactDealerClickParamsType = {
    context: UscContext;
    dealer: DealerResultType | null;
    vehicle: UscVehicleType | null;
    detailPageUrl: string;
    localContactDealerFormUrl: string;
    trackingFn?: () => void;
    calculationId?: string | null;
    financeQuoteId?: string | null;
    tyCode?: string;
};

const useLocalContactDealerClick = (params: UseLocalContactDealerClickParamsType): (() => void) => {
    const { context, dealer, vehicle, trackingFn } = params;
    const { detailPageUrl, localContactDealerFormUrl } = params;
    const { calculationId, financeQuoteId, tyCode } = params;

    const dispatch = useDispatch<CommonDispatchType>();
    const commonSettings = useCommonSelector((state) => state.commonSettings);

    // TGB requested to hide the VIN parameter from the URL as it gets used
    // in their tracking software and according to regulations, it can't be
    // visible. To solve this issue, we proposed that they should add ?hideVIN=true
    // to the URL to hide the VIN parameter, which triggers below PostMessage logic.
    // As I don't see this feature expanding in the future, this is not a very dynamic
    // solution. If I'm wrong, I see two possible solutions:
    // 1. Expand the feature to hide any parameter from the URL
    // 2. Refactor the whole local form system to make use of the PostMessage API as we're doing for hideVIN right now
    const hideVIN = localContactDealerFormUrl.includes("hideVIN=true");

    const clearIframe = useCallback((): void => {
        const container = document.getElementById(CONTAINER_ID);

        if (container) {
            container.innerHTML = "";
        }

        dispatch(hideModal(MODAL_USC_INJECTION));
    }, [dispatch]);

    /**
     * Sends the VIN to the iframe via the PostMessage API.
     * As we want to make sure the VIN is received by the iframe, we send it multiple times
     * using exponential backoff until we receive a confirmation from the iframe ("ack").
     */
    const sendVINToIframe = (iframe: HTMLIFrameElement, vin: string): void => {
        let retryCount = 0;
        const maxRetries = 10;
        const initialDelay = 500; // 500ms
        let ackReceived = false;

        const sendVIN = (): void => {
            if (retryCount >= maxRetries || ackReceived) {
                if (!ackReceived) {
                    Debug.error(`VIN was not received and acknowledged by iframe after ${maxRetries} retries`);
                }
                return;
            }

            iframe?.contentWindow?.postMessage({ type: VIN_MESSAGE_TYPE, value: vin }, "*");
            retryCount++;

            // Calculate delay with exponential backoff
            // Basically, we wait 500ms, 1000ms, 2000ms, 4000ms, 8000ms
            // This is done to prevent spamming the iframe with messages
            // and to give it time to process the message
            const delay = initialDelay * 2 ** (retryCount - 1);

            // Set up acknowledgment listener
            const ackListener = (event: MessageEvent): void => {
                if (
                    event.source === iframe.contentWindow && // Ensure the message is from the iframe
                    typeof event.data === "object" && // Ensure the message is an object
                    event.data !== null &&
                    event.data.type === ACK_MESSAGE_TYPE
                ) {
                    Debug.debug("VIN received by iframe and acknowledged");
                    ackReceived = true;
                    window.removeEventListener("message", ackListener);
                }
            };

            window.addEventListener("message", ackListener);

            setTimeout(() => {
                // If we haven't received an ack, remove the listener and try again
                if (!ackReceived) {
                    window.removeEventListener("message", ackListener);
                    sendVIN();
                }
            }, delay);
        };

        sendVIN();
    };

    return useCallback((): void => {
        let tempIframeUrl = localContactDealerFormUrl;
        if (vehicle) {
            const vehicleUrl = getUscUrl(
                commonSettings,
                detailPageUrl,
                context,
                getUscVehicleUrlInfoFromVehicle(vehicle),
            );

            const localFormData = getLocalDealerFormData(
                vehicle,
                vehicleUrl,
                dealer,
                calculationId,
                financeQuoteId,
                tyCode,
            );

            const urlParams = (Object.keys(localFormData) as (keyof ReturnType<typeof getLocalDealerFormData>)[])
                .filter((key) => !!localFormData[key])
                .filter((key) => !(key === VIN_URL_PARAM && hideVIN))
                .map((key) => `${String(key)}=${String(localFormData[key])}`)
                .join("&");
            tempIframeUrl += `${tempIframeUrl.includes("?") ? "&" : "?"}${urlParams}`;
        }

        trackingFn?.();

        /**
         * While iframes aren't considered as injection (like the insurance/finance calculators),
         * we can still use the same modal logic to show the iframe and just consider it as an injection.
         */
        dispatch(
            showModal({
                type: MODAL_USC_INJECTION,
                containerId: CONTAINER_ID,
                onClose: clearIframe,
                onCloseAnimationEnd: clearIframe,
                title: "", // No label as the iframe will contain the form (and thus should have its own label)
                injectionLoading: true,
            }),
        );

        window.requestAnimationFrame(() => {
            const container = document.getElementById(CONTAINER_ID);
            if (container) {
                const iframe = document.createElement("iframe");

                /**
                 * iframes start rendering as soon as they are appended to the DOM.
                 * As we show a loading indicator while the iframe is loading,
                 * we need to hide the iframe until it's fully loaded.
                 */
                iframe.style.display = "none";

                iframe.setAttribute("src", tempIframeUrl);
                iframe.setAttribute("height", `${window.innerHeight - 125}`);
                iframe.setAttribute("width", "100%");

                iframe.onload = () => {
                    dispatch(updateModalSettings({ injectionLoading: false }, MODAL_USC_INJECTION));

                    // The content has truly loaded, so we can show the iframe now.
                    iframe.style.display = "block";

                    if (hideVIN && vehicle?.vin) {
                        sendVINToIframe(iframe, vehicle.vin);
                    }
                };

                container.appendChild(iframe);
            }
        });
    }, [
        dispatch,
        commonSettings,
        context,
        dealer,
        detailPageUrl,
        financeQuoteId,
        calculationId,
        localContactDealerFormUrl,
        tyCode,
        vehicle,
        trackingFn,
        hideVIN,
        clearIframe,
    ]);
};

export default useLocalContactDealerClick;
