import {
    DfCarSortOrder,
    LocationFilterIdType,
    MinMaxSliderFilterIdType,
    MultipleChoiceFilterIdType,
    PriceFilterIdType,
    stockCarMinMaxSliderFilterIds,
    StockCarMinMaxSliderFilterIdType,
    stockCarMultipleChoiceFilterIds,
    StockCarMultipleChoiceFilterIdType,
    usedCarMinMaxSliderFilterIds,
    UsedCarMinMaxSliderFilterIdType,
    usedCarMultipleChoiceFilterIds,
    UsedCarMultipleChoiceFilterIdType,
} from "./constants/filterConstants";
import {
    CarFilterIdType,
    StockCarFilterId,
    UsedCarFilterId,
    UsedCarResultType,
    UscContext,
} from "../../shared-logic/types/UscCommonTypes";
import { CarFiltersReducerType } from "../redux/reducers/CarFiltersReducer";
import { FilterRequestBodyType, FilterRequestReturnType } from "./constants/filterRequestConstants";
import {
    CarTypeFilterModelType,
    MultipleChoiceValueSortOption,
    MultipleChoiceValueType,
    PriceFilterConfigType,
} from "../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { getAPI } from "../../../common-deprecated/settings/utils/commonSettingUtils";
import { CarFilterSettingsType } from "../redux/reducers/CarFilterSettingsReducer";
import { CarFilterDispatchType, CarFilterStateType } from "../redux/store";
import {
    resetLocation,
    resetMultiFilter,
    resetPriceFilter,
    resetSlider,
    setActiveFilters,
} from "../redux/actions/CarFiltersActions";
import { getMinMaxQueryValue, sortMultipleChoiceValues } from "../../../shared-logic/features/filters/utils/filters";
import {
    getMCSentenceLabel,
    getMinMaxSentenceLabel,
    LabelReturnType,
} from "../../../shared-logic/features/filters/utils/filterLabelUtils";
import { FinanceOptionType } from "../../../common-deprecated/types/CommonTypes";
import { formatPriceIntl } from "../../../common-deprecated/priceUtils";
import { getDisabledFiltersIds } from "./formatters/common";
import { LEXUS_BRAND_ID, TOYOTA_BRAND_ID } from "../../shared-logic/utils/dfConstants";

// ----------------------------------------------------------------------
// Helpers to determine filter types based on id
// ----------------------------------------------------------------------
export const isMinMaxSlider = (filterId: CarFilterIdType): filterId is MinMaxSliderFilterIdType =>
    usedCarMinMaxSliderFilterIds.includes(filterId as UsedCarMinMaxSliderFilterIdType) ||
    stockCarMinMaxSliderFilterIds.includes(filterId as StockCarMinMaxSliderFilterIdType);

export const isMultipleChoice = (filterId: CarFilterIdType): filterId is MultipleChoiceFilterIdType =>
    usedCarMultipleChoiceFilterIds.includes(filterId as UsedCarMultipleChoiceFilterIdType) ||
    stockCarMultipleChoiceFilterIds.includes(filterId as StockCarMultipleChoiceFilterIdType);

export const isPrice = (filterId: CarFilterIdType): filterId is PriceFilterIdType =>
    filterId === UsedCarFilterId.Price || filterId === StockCarFilterId.Price;

export const isDeliverable = (filterId: CarFilterIdType): filterId is UsedCarMultipleChoiceFilterIdType =>
    filterId === UsedCarFilterId.Deliverable;

export const getLocationFilterId = (currentContext: UscContext): LocationFilterIdType =>
    currentContext === UscContext.Used ? UsedCarFilterId.Location : StockCarFilterId.Location;

export const isHiddenFilter = (filterId: CarFilterIdType): boolean => isDeliverable(filterId);

// ----------------------------------------------------------------------
// Filter request body helpers
// ----------------------------------------------------------------------

export const getCarResultBody = (
    carFilters: CarFiltersReducerType,
    carFilterSettings: CarFilterSettingsType,
    offset: number,
    resultCount: number,
    sortOrder: DfCarSortOrder,
    disabledFiltersIds: CarFilterIdType[],
    includeActiveAggregations: boolean = false,
    vehicleForSaleId?: string,
): FilterRequestBodyType => {
    const { uscEnv, distributorCode } = carFilterSettings;

    const { usedCarsEnableBiasedSort, usedCarsDisableSavedCars, stockCarsDisableSavedCars } = carFilterSettings;
    const { usedCarsEnableExperimentalTotalCountQuery, stockCarsEnableExperimentalTotalCountQuery } = carFilterSettings;
    const { usedCarsEnableVehicleAggregations, stockCarsEnableVehicleAggregations } = carFilterSettings;
    const { usedCarsVehicleAggregationsVersionCode, stockCarsVehicleAggregationsVersionCode } = carFilterSettings;

    const data: FilterRequestBodyType = {
        uscEnv,
        filters: [],
        filterContext: carFilters.currentFilter,
        offset,
        resultCount,
        sortOrder,
        distributorCode,
        includeActiveFilterAggregations: includeActiveAggregations,
        enableBiasedSort: usedCarsEnableBiasedSort,
        disabledFiltersIds,
        enableExperimentalTotalCountQuery:
            carFilters.currentFilter === UscContext.Used
                ? usedCarsEnableExperimentalTotalCountQuery
                : stockCarsEnableExperimentalTotalCountQuery,
        vehicleForSaleId,
        enableVehicleAggregations:
            carFilters.currentFilter === UscContext.Used
                ? usedCarsEnableVehicleAggregations
                : stockCarsEnableVehicleAggregations,
        vehicleAggregationsVersionCode:
            carFilters.currentFilter === UscContext.Used
                ? usedCarsVehicleAggregationsVersionCode
                : stockCarsVehicleAggregationsVersionCode,
        hasContentBlock: !!getContentBlockConfig(carFilterSettings, carFilters.currentFilter),
    };

    if (sortOrder === DfCarSortOrder.Distance) {
        const { dealerCache } = carFilters[getLocationFilterId(carFilters.currentFilter)];
        data.dealerSortOrder = dealerCache.sort((a, b) => a.distance - b.distance).map((dealer) => dealer.id);
    }

    type DFFilterIdType = (UsedCarFilterId | StockCarFilterId)[];

    const filterIds: DFFilterIdType = (Object.values(UsedCarFilterId) as DFFilterIdType).concat(
        Object.values(StockCarFilterId) as DFFilterIdType,
    );

    filterIds
        .filter((filterId) => carFilters[filterId].active)
        .forEach((filterId) => {
            if (filterId === UsedCarFilterId.Price || filterId === StockCarFilterId.Price) {
                if (carFilters[filterId].cash.active) {
                    data.filters.push({
                        filterId: "cash",
                        ...getMinMaxQueryValue(carFilters[filterId].cash),
                    });
                }
                if (carFilters[filterId].monthly.active) {
                    data.filters.push({
                        filterId: "monthly",
                        ...getMinMaxQueryValue(carFilters[filterId].monthly),
                    });
                }
            } else if (filterId === UsedCarFilterId.Location || filterId === StockCarFilterId.Location) {
                const dealerGroupFilter = carFilters[filterId].dealerGroup;
                const dealerHoldingFilter = carFilters[filterId].dealerHolding;
                const dealerFilter = carFilters[filterId].dealer;
                const rangeFilter = carFilters[filterId].range;
                if (dealerGroupFilter) {
                    // Dealer group is set, add all the ids of the dealers.
                    data.filters.push({ filterId, valueIds: dealerGroupFilter.dealerIds });
                } else if (dealerHoldingFilter) {
                    // Dealer holding is set, add all the ids of the dealers.
                    data.filters.push({ filterId, valueIds: dealerHoldingFilter.dealerIds });
                } else if (dealerFilter) {
                    // Single dealer is set, add the single ID to the filters.
                    data.filters.push({ filterId, valueIds: [dealerFilter.id] });
                } else if (rangeFilter) {
                    // If the range is zero the user selected "national" which basically means no filter so don't do anything.
                    // If a range is set retrieve the relevant dealer ids from the dealerCache and use them as a filter.
                    if (rangeFilter.range !== 0) {
                        const dealerIds = carFilters[filterId].dealerCache
                            .filter((dealer) => dealer.distance <= rangeFilter.range)
                            .map((dealer) => dealer.id);

                        data.filters.push({ filterId, valueIds: dealerIds });
                    }
                }
            } else if (filterId === UsedCarFilterId.Mileage && carFilters[filterId].zeroMileage) {
                data.filters.push({ filterId, zeroMileage: true, ...getMinMaxQueryValue(carFilters[filterId]) });
            } else if (isMultipleChoice(filterId)) {
                const valueIds = carFilters[filterId].values
                    .filter((filterValue) => filterValue.selected)
                    .map((filterValue) => filterValue.id);

                if (valueIds.length) data.filters.push({ filterId, valueIds });
            } else if (isMinMaxSlider(filterId)) {
                data.filters.push({ filterId, ...getMinMaxQueryValue(carFilters[filterId]) });
            }
        });

    // Add the carId filter which is an array of vehicleForSaleIds.
    const { savedCars, currentFilter } = carFilters;
    if (
        (carFilters.currentFilter === UscContext.Used ? !usedCarsDisableSavedCars : !stockCarsDisableSavedCars) &&
        carFilters.savedCars.showOnlySavedCars
    ) {
        data.filters.push({ filterId: "carId", valueIds: savedCars[currentFilter] });
    }

    if (carFilters.similarCar.isActive) {
        data.excludedCarIds = [carFilters.similarCar.vehicleForSaleId];
    }

    return data;
};

// ----------------------------------------------------------------------
// Fetch result functions
// ----------------------------------------------------------------------
/**
 * Fetch new used car results based on the provided carFilter state.
 */
export const fetchDFCarResults = async (
    commonSettings: CommonSettingsType,
    carFilterSettings: CarFilterSettingsType,
    carFilters: CarFiltersReducerType,
    offset: number,
    resultCount: number,
    sortOrder: DfCarSortOrder,
    includeActiveAggregations: boolean = false,
): Promise<FilterRequestReturnType<UsedCarResultType> | null> => {
    if (!carFilterSettings.distributorCode) throw new Error("No distributor code configured.");

    const disabledFiltersIds = getDisabledFiltersIds(commonSettings) as CarFilterIdType[];

    // Attach brand as USC runs both toyota and lexus on the same environments so we need to know which brand is active.
    const usedCarResultApi = `${getAPI(commonSettings, "usedcars/results")}?brand=${commonSettings.brand}`;
    const data = getCarResultBody(
        carFilters,
        carFilterSettings,
        offset,
        resultCount,
        sortOrder,
        disabledFiltersIds,
        includeActiveAggregations,
        !carFilters.similarCar.isActive ? commonSettings.query.vehicleForSaleId : undefined,
    );

    const fetchResult = await fetch(usedCarResultApi, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        referrer: "no-referrer",
        body: JSON.stringify(data),
    });

    return fetchResult.status === 200 ? fetchResult.json() : null;
};

/**
 * Sorts an array by label with a bias for Toyota/Lexus based in the TOYOTA_BRAND_ID and LEXUS_BRAND_ID constants.
 */
export const biasedSort = <T extends { id: string; brandId?: string }>(
    valueArray: T[],
    brand: "toyota" | "lexus",
    brandIdProp: "id" | "brandId" = "id",
): T[] =>
    brand === "toyota"
        ? valueArray.sort((a, b) => {
              // Toyota first, Lexus second, rest after.
              if (a[brandIdProp] === TOYOTA_BRAND_ID) return -1;
              if (b[brandIdProp] === TOYOTA_BRAND_ID) return 1;
              if (a[brandIdProp] === LEXUS_BRAND_ID) return -1;
              if (b[brandIdProp] === LEXUS_BRAND_ID) return 1;
              return 0;
          })
        : valueArray.sort((a, b) => {
              // Lexus first, Toyota second, rest after.
              if (a[brandIdProp] === LEXUS_BRAND_ID) return -1;
              if (b[brandIdProp] === LEXUS_BRAND_ID) return 1;
              if (a[brandIdProp] === TOYOTA_BRAND_ID) return -1;
              if (b[brandIdProp] === TOYOTA_BRAND_ID) return 1;
              return 0;
          });

/**
 * Get an array containing all the filters based on the currentFilter prop.
 */
export const getCurrentFilters = (currentFilter: UscContext): CarFilterIdType[] => {
    switch (currentFilter) {
        case UscContext.Used:
            return Object.values(UsedCarFilterId);

        case UscContext.Stock:
            return Object.values(StockCarFilterId);

        default:
            return [];
    }
};

/**
 * Returns true when a filter is active
 */
export const isFilterActive = (filterId: CarFilterIdType, carFilters: CarFiltersReducerType): boolean => {
    return carFilters[filterId]?.active;
};

/**
 * Helper function to reset filters
 */
export const getFilterReset = (filterId: CarFilterIdType, dispatch: CarFilterDispatchType): void => {
    if (filterId === UsedCarFilterId.Price || filterId === StockCarFilterId.Price) {
        dispatch(resetPriceFilter(filterId));
    }

    if (filterId === UsedCarFilterId.Location || filterId === StockCarFilterId.Location) {
        dispatch(resetLocation(filterId));
    }

    if (isMinMaxSlider(filterId)) {
        dispatch(resetSlider(filterId));
    }

    if (isMultipleChoice(filterId)) {
        dispatch(resetMultiFilter(filterId));
    }
};

/**
 * Helper function to clear all the active filters in the car filter
 */
export const clearAllFilters = (dispatch: CarFilterDispatchType, carFilters: CarFiltersReducerType): void => {
    const filterIds = getCurrentFilters(carFilters.currentFilter);
    // Filters out all active filters but makes sure the disabled filters stay disabled
    const activeFilters = filterIds.filter((filterId) => carFilters[filterId].active && !carFilters[filterId].disabled);

    activeFilters.forEach((filterId) => getFilterReset(filterId, dispatch));

    // close opened filter dropdowns
    dispatch(setActiveFilters([]));
};

/*
 * Round a filter value based on a min/max value and a step.
 * Used for calculating the correct value of a slider filter.
 */
export const getRoundedFilterValue = (value: number, minValue: number, maxValue: number, step: number): number => {
    // Step rounding: Round the value to the nearest multiple of the step.
    // Take the min and max values into account, they should be able to be selected even when they are not a step multiple.
    // This will also make sure the rounded value does not go below the minValue and doesn't exceed the max value.
    if (value - step / 2 < minValue) return minValue;
    else if (value + step / 2 > maxValue) return maxValue;
    else return Math.round(value / step) * step;
};

/**
 * Returns a set of maximum 4 filter ids which NMSCs have configured
 * to be the main filters for the current context. These get used in the
 * Top Filter components.
 */
export const mainFilterSelector = (state: CarFilterStateType): CarFilterIdType[] => {
    const { carFilters, carFilterSettings } = state;

    // Determine which set of filters to use based on the current filter
    const filters: CarFilterIdType[] =
        carFilters.currentFilter === UscContext.Stock
            ? carFilterSettings.stockCarMainFilters
            : carFilterSettings.usedCarMainFilters;

    // Filter the enabled and shown filters, and limit the result to 4 filters
    return filters.filter((filterId) => !carFilters[filterId].disabled && carFilters[filterId].show).slice(0, 4);
};

/**
 * Helper to get labels for car filter values (the literal filters, not the component as a whole)
 */
export const getCarFilterLabels = (
    filterId: CarFilterIdType,
    filters: CarFiltersReducerType,
    commonSettings: CommonSettingsType,
): { midLabel: LabelReturnType; filterCount: number } => {
    const { anyLabel } = filters[filterId]!.sentenceConfig;
    let midLabel: LabelReturnType = [{ text: anyLabel, strikethrough: false }];
    let filterCount = 0;

    if ((filterId === UsedCarFilterId.Price || filterId === StockCarFilterId.Price) && filters[filterId]!.active) {
        const activeFinanceOption = filters[filterId]!.cash.active ? "cash" : "monthly";
        midLabel = getCarFilterPriceSentenceLabel(filters[filterId]!, activeFinanceOption, commonSettings);
        filterCount = 1;
    }

    if (filterId === UsedCarFilterId.Mileage) {
        midLabel = getMinMaxSentenceLabel(filters[filterId]);
        if (filters[filterId]!.active) filterCount = 1;
    }

    if (filterId === UsedCarFilterId.Location || filterId === StockCarFilterId.Location) {
        if (filters[filterId]!.active) {
            let currentValue =
                filters[filterId]!.dealerGroup?.name ||
                filters[filterId]!.dealerHolding?.name ||
                filters[filterId]!.dealer?.name ||
                filters[filterId]!.range?.name ||
                "";

            // Trim currentValue to 25 characters to prevent the sentence of being overly long.
            if (currentValue.length > 25) currentValue = `${currentValue.substring(0, 24)}...`;

            const replacementValue = filters[filterId]!.sentenceConfig.valueLabel.replace("{value}", currentValue);
            midLabel = [{ text: replacementValue, strikethrough: false }];
            filterCount = 1;
        } else {
            midLabel = [{ text: filters[filterId]!.sentenceConfig.anyLabel, strikethrough: false }];
        }
    }

    if (isMinMaxSlider(filterId)) {
        midLabel = getMinMaxSentenceLabel(filters[filterId]!);
        if (filters[filterId]!.active) filterCount = 1;
    }

    if (isMultipleChoice(filterId)) {
        midLabel = getMCSentenceLabel(filters[filterId]!.values, filters[filterId]!.sentenceConfig, true);
        filterCount = filters[filterId]!.values.filter((value) => value.selected).length;
    }
    return { midLabel, filterCount };
};

/**
 * This function is used to format the brands for the dropdown in the Brand Filter.
 * First, we sort all brands aside from Toyota and Lexus by label.
 * Finally, we sort Toyota and Lexus to the top of the list (depending on the current brand)
 * and all other brands to the bottom.
 */
export const formatBrands = (
    brands: MultipleChoiceValueType[],
    currentBrand: "toyota" | "lexus",
    sortOption: MultipleChoiceValueSortOption,
): MultipleChoiceValueType[] => {
    const toyotaBrand = brands.find((brand) => brand.id === TOYOTA_BRAND_ID);
    const lexusBrand = brands.find((brand) => brand.id === LEXUS_BRAND_ID);
    const sortedBrands = currentBrand === "toyota" ? [toyotaBrand, lexusBrand] : [lexusBrand, toyotaBrand];
    const otherBrands = sortMultipleChoiceValues(brands, sortOption).filter(
        (brand) => brand.id !== TOYOTA_BRAND_ID && brand.id !== LEXUS_BRAND_ID,
    );

    return [...sortedBrands, ...otherBrands].filter((brand): brand is MultipleChoiceValueType => !!brand); // Filter out undefined values
};

/**
 * Selector that returns the values of the brand filter based on the current filter context.
 */
export const brandFilterValuesSelector = (state: CarFilterStateType): MultipleChoiceValueType[] => {
    const filterContext = state.carFilters.currentFilter;

    switch (filterContext) {
        case UscContext.Used:
            return state.carFilters[UsedCarFilterId.Brand].values;
        case UscContext.Stock:
            return state.carFilters[StockCarFilterId.Brand].values;
        default:
            return [];
    }
};

/**
 * Selector that returns the values of the model filter based on the current filter context.
 */
export const modelFilterValuesSelector = (state: CarFilterStateType): CarTypeFilterModelType[] => {
    const filterContext = state.carFilters.currentFilter;

    switch (filterContext) {
        case UscContext.Used:
            return state.carFilters[UsedCarFilterId.Model].values;
        case UscContext.Stock:
            return state.carFilters[StockCarFilterId.Model].values;
        default:
            return [];
    }
};

export const getCarFilterPriceSentenceLabel = (
    filter: PriceFilterConfigType,
    financeOption: FinanceOptionType,
    commonSettings: CommonSettingsType,
): LabelReturnType => {
    const { cash, monthly, sentenceConfig } = filter;
    const { finalValueSeparator, valueSeparator, valueLabel } = sentenceConfig;

    const { currentMinValue, currentMaxValue, customMinValue, customMaxValue } =
        financeOption === "monthly" ? monthly : cash;

    // TODO: OR-9072 support secondary price
    const minValueString = String(
        formatPriceIntl(commonSettings, Math.max(currentMinValue, customMinValue)).primaryPrice,
    );
    const maxValueString = String(
        formatPriceIntl(
            commonSettings,
            Math.min(currentMaxValue, customMaxValue > 0 ? customMaxValue : currentMaxValue),
        ).primaryPrice,
    );

    // Legacy handling.
    if (valueLabel.includes("{value}")) {
        const minValue = valueLabel.replace("{value}", minValueString);
        const maxValue = valueLabel.replace("{value}", maxValueString);

        const separator = finalValueSeparator || valueSeparator;
        return [{ text: `${minValue}${separator}${maxValue}`, strikethrough: false }];
    }

    // Min-max handling (Used in Model Filter)
    return [
        { text: valueLabel.replace("{min}", minValueString).replace("{max}", maxValueString), strikethrough: false },
    ];
};

export const getContentBlockConfig = (
    carFilterSettings: CarFilterSettingsType,
    context: UscContext,
): null | CarFilterSettingsType["contentBlockConfigurations"][number] => {
    return carFilterSettings.contentBlockConfigurations.find((config) => config.context === context) || null;
};
