export const COMPONENT_NAME_TRADEIN = "trade_in" as const;
export const COMPONENT_NAME_GRADE_FILTER = "grade_filter" as const;
export const COMPONENT_NAME_GRADE_FILTER_RESULTS = "grade_filter_results" as const;
export const COMPONENT_NAME_STANDARD_EQUIPMENT = "standard-equipment" as const;
export const COMPONENT_NAME_OPTIONAL_EQUIPMENT = "optional-equipment" as const;
export const COMPONENT_NAME_PROACE_SPECS = "equipment-filter" as const;
export const COMPONENT_NAME_FILTERS_HOMEPAGE = "filters_homepage" as const;
export const COMPONENT_NAME_FILTERS_RESULTS = "filters_results" as const;
export const COMPONENT_NAME_MODEL_FILTER = "model-filter" as const; // used for both new, used & stock model/car filter

// This is not a complete list of component names, newer or upgraded components use hardcoded values in a dedicated tracking.ts file instead
export type TrackComponentType =
    | typeof COMPONENT_NAME_FILTERS_HOMEPAGE
    | typeof COMPONENT_NAME_FILTERS_RESULTS
    | typeof COMPONENT_NAME_TRADEIN
    | typeof COMPONENT_NAME_GRADE_FILTER
    | typeof COMPONENT_NAME_GRADE_FILTER_RESULTS
    | typeof COMPONENT_NAME_OPTIONAL_EQUIPMENT
    | typeof COMPONENT_NAME_STANDARD_EQUIPMENT
    | typeof COMPONENT_NAME_PROACE_SPECS
    | typeof COMPONENT_NAME_MODEL_FILTER;
