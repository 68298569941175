import SvgChevronUp from "../../assets/icons/sm/chevron-up.svg";
import SvgChevronDown from "../../assets/icons/sm/chevron-down.svg";
import SvgChevronRight from "../../assets/icons/sm/chevron-right.svg";
import SvgChevronLeft from "../../assets/icons/sm/chevron-left.svg";
import SvgLocation from "../../assets/icons/sm/location.svg";
import SvgFinancing from "../../assets/icons/sm/financing.svg";
import SvgBasketVariant from "../../assets/icons/sm/basket-variant.svg";
import SvgBook from "../../assets/icons/sm/book.svg";
import SvgInsurance from "../../assets/icons/sm/insurance.svg";
import SvgDelivery from "../../assets/icons/sm/delivery.svg";
import SvgTradeInDots from "../../assets/icons/sm/trade-in-dots.svg";
import SvgTradeIn from "../../assets/icons/sm/trade-in.svg";
import SvgCourtesyVan from "../../assets/icons/sm/courtesy-van.svg";
import SvgWarranty from "../../assets/icons/sm/warranty.svg";
import SvgCheck from "../../assets/icons/sm/check.svg";
import SvgCard from "../../assets/icons/sm/card.svg";
import SvgManual from "../../assets/icons/sm/manual.svg";
import SvgHeart from "../../assets/icons/sm/heart.svg";
import SvgHeartFilled from "../../assets/icons/sm/heart-filled.svg";
import SvgArrowRight from "../../assets/icons/sm/arrow-right.svg";
import SvgClose from "../../assets/icons/sm/close.svg";
import SvgPlus from "../../assets/icons/sm/plus.svg";
import SvgInfo from "../../assets/icons/sm/info.svg";
import SvgSearch from "../../assets/icons/sm/search.svg";
import SvgFullscreen from "../../assets/icons/sm/fullscreen.svg";
import SvgCar from "../../assets/icons/sm/car.svg";
import SvgSteeringWheel from "../../assets/icons/sm/steering-wheel.svg";
import SvgTruck from "../../assets/icons/sm/truck.svg";
import SvgDownload from "../../assets/icons/sm/download.svg";
import SvgProfile from "../../assets/icons/sm/profile.svg";
import SvgProfileRing from "../../assets/icons/sm/profile-ring.svg";
import SvgError from "../../assets/icons/sm/error.svg";
import SvgApproved from "../../assets/icons/sm/toyota-approved.svg";
import SvgNonApproved from "../../assets/icons/sm/toyota-non-approved.svg";
import SvgVehicleAggregationFewCars from "../../assets/icons/sm/vehicle-aggregations-fewer-cars.svg";
import SvgVehicleAggregationMultipleCars from "../../assets/icons/sm/vehicle-aggregations-multiple-cars.svg";

export const UscIconVariants = {
    "chevron-up": SvgChevronUp,
    "chevron-down": SvgChevronDown,
    "chevron-right": SvgChevronRight,
    "chevron-left": SvgChevronLeft,
    location: SvgLocation,
    financing: SvgFinancing,
    "basket-variant": SvgBasketVariant,
    book: SvgBook,
    insurance: SvgInsurance,
    delivery: SvgDelivery,
    "trade-in-dots": SvgTradeInDots,
    "trade-in": SvgTradeIn,
    "courtesy-van": SvgCourtesyVan,
    warranty: SvgWarranty,
    check: SvgCheck,
    card: SvgCard,
    manual: SvgManual,
    heart: SvgHeart,
    "heart-filled": SvgHeartFilled,
    "arrow-right": SvgArrowRight,
    close: SvgClose,
    plus: SvgPlus,
    info: SvgInfo,
    search: SvgSearch,
    fullscreen: SvgFullscreen,
    car: SvgCar,
    "steering-wheel": SvgSteeringWheel,
    truck: SvgTruck,
    download: SvgDownload,
    profile: SvgProfile,
    "profile-ring": SvgProfileRing,
    error: SvgError,
    approved: SvgApproved,
    "non-approved": SvgNonApproved,
    "vehicle-aggregations-fewer-cars": SvgVehicleAggregationFewCars,
    "vehicle-aggregations-multiple-cars": SvgVehicleAggregationMultipleCars,
};

export type UscIconVariantType = keyof typeof UscIconVariants;

export const ToyotaIconVariants = {
    "customer-centric": "Customer_Centric",
    profile: "Profile",
    "satisfied-customer": "Satisfied_Customer",
    "user-notification": "User_Notification",
    spin: "Spin",
    charger: "Charger",
    "electric-station": "Electric_Station",
    "find-dealer": "Find_Dealer",
    gas: "Gas",
    globe: "Globe",
    highway: "Highway",
    language: "Language",
    location: "Location",
    map: "Map",
    "map-digital": "Map_Digital",
    "no-dharger": "No_Charger",
    route: "Route",
    "self-charging": "Self_Charging",
    calendar: "Calendar",
    "extended-hours": "Extended_Hours",
    fast: "Fast",
    "express-service": "Express_Service",
    "fast-lane-for-specific-services": "Fast_Lane_For_Specific_Services",
    "free-tech-check": "Free_Tech_Check",
    time: "Time",
    "time-dotted": "Time_Dotted",
    "call-center": "Call_Center",
    "newsletter-email": "Newsletter_Email",
    print: "Print",
    smartphone: "Smartphone",
    website: "Website",
    wifi: "Wifi",
    "commercial-insurance": "Commercial_Insurance",
    "financing-and-insurance": "Financing_and_Insurance",
    insurance: "Insurance",
    "insurance-calculator": "Insurance_Calculator",
    "insurance-customer-portal": "Insurance_Customer_Portal",
    lock: "Lock",
    "motor-insurance": "Motor_Insurance",
    check: "Check",
    compare: "Compare",
    "compare-lines": "Compare_Lines",
    controls: "Controls",
    customize: "Customize",
    "customize-black": "Customize_Black",
    "external-link": "External_Link",
    flexible: "Flexible",
    fullscreen: "Fullscreen",
    "hybrid-health-check": "Hybrid_Health_Check",
    "no-sound": "No_Sound",
    player: "Player",
    search: "Search",
    sort: "Sort",
    sound: "Sound",
    trade: "Trade",
    trash: "Trash",
    "view-off": "View_Off",
    "view-on": "View_On",
    "arrow-down": "Arrow_Down",
    "arrow-left": "Arrow_Left",
    "arrow-right": "Arrow_Right",
    "arrow-up": "Arrow_Up",
    "chevron-down": "Chevron_Down",
    "chevron-left": "Chevron_Left",
    "chevron-right": "Chevron_Right",
    "chevron-up": "Chevron_Up",
    close: "Close",
    explore: "Explore",
    menu: "Menu",
    minus: "Minus",
    plus: "Plus",
    support: "Support",
    "courtesy-van": "Courtesy_Van",
    "customer-loyalty": "Customer_Loyalty",
    easy: "Easy",
    hand: "Hand",
    like: "Like",
    "quality-assurance": "Quality_Assurance",
    "safe-driving": "Safe_Driving",
    card: "Card",
    coins: "Coins",
    "excess-bonus": "Excess_Bonus",
    finance: "Finance",
    money: "Money",
    "money-hand": "Money_Hand",
    "piggy-bank": "Piggy_Bank",
    "save-money": "Save_Money",
    wallet: "Wallet",
    assessment: "Assessment",
    "conventional-warranty": "Conventional_Warranty",
    download: "Download",
    form: "Form",
    "hybrid-warranty": "Hybrid_Warranty",
    image: "Image",
    invoice: "Invoice",
    jpg: "JPG",
    link: "Link",
    manual: "Manual",
    mp4: "MP4",
    pdf: "PDF",
    "point-check": "Point_Check",
    quality: "Quality",
    "ready-to-go": "Ready_To_Go",
    reserve: "Reserve",
    star: "Star",
    "tyre-match": "Tyre_Match",
    upload: "Upload",
    video: "Video",
    cloud: "Cloud",
    "cloud-complete": "Cloud_Complete",
    "cloud-rain": "Cloud_Rain",
    co2: "CO2",
    environmental: "Environmental",
    "green-promise": "Green_Promise",
    lightning: "Lightning",
    "mountain-fffice": "Mountain_Office",
    tree: "Tree",
    basket: "Basket",
    "shopping-bag": "Shopping_Bag",
    "shopping-cart": "Shopping_Cart",
    "visits-dealership": "Visits_Dealership",
    chat: "Chat",
    chatbox: "Chatbox",
    "personalized-feedback": "Personalized_Feedback",
    anticipation: "Anticipation",
    elegance: "Elegance",
    h: "H",
    "my-t": "MyT",
    simplicity: "Simplicity",
    bell: "Bell",
    "bell-notification": "Bell_Notification",
    error: "Error",
    info: "Info",
    "road-assistance": "Road_Assistance",
    calculator: "Calculator",
    "moving-tool": "Moving_Tool",
    "personalized-feedback-statistic": "Personalized_Feedback_Statistic",
    repair: "Repair",
    settings: "Settings",
    tool: "Tool",
    treat: "Treat",
    "engine-temperature": "Engine_Temperature",
    "error-ring": "Error_Ring",
    ev: "EV",
    meters: "Meters",
    mileage: "Mileage",
    speed: "Speed",
    speed1: "Speed-1",
    speedometer: "Speedometer",
    "steering-wheel-alert": "Steering_Wheel-_Alert",
    "air-vent": "Air_Vent",
    airbag: "Airbag",
    battery: "Battery",
    engine: "Engine",
    "interior-car": "Interior_Car",
    mobility: "Mobility",
    "parts-sales": "Parts_Sales",
    "steering-wheel": "Steering_Wheel",
    tyres: "Tyres",
    wheel: "Wheel",
    "wheel-safety": "Wheel_Safety",
    accelerations: "Accelerations",
    "battery-electric-car-bev": "Battery_Electric_Car_BEV",
    car: "Car",
    "cars-exchange": "Cars_Exchange",
    collision: "Collision",
    "diesel-petrol-car": "Diesel_Petrol_Car",
    "exceptional-event": "Exceptional_Event",
    "hard-brakes": "Hard_Brakes",
    "hybrid-electric-car": "Hybrid_Electric_Car",
    "hydrogen-car": "Hydrogen_Car",
    lcv: "LCV",
    "mild-hybrid-car": "Mild_Hybrid_Car_MHV",
    "pick-up-service": "Pick_Up_Service",
    "plugin-hybrid-car-phev": "Plug_In_Hybrid_Car_PHEV",
    "repairs-workshop": "Repairs_Workshop",
    "road-service-assistance": "Road_Service_Assistance",
    sensor: "Sensor",
    "theft-fire": "Theft_Fire",
    "vehicle-replacement": "Vehicle_Replacement",
    facebook: "Facebook",
    instagram: "Instagram",
    linkedin: "LinkedIn",
    telegram: "Telegram",
    tiktok: "TikTok",
    twitter: "Twitter",
    vk: "VK",
    whatsapp: "WhatsApp",
    yandexzen: "Yandex-Zen",
    youtube: "Youtube",
    gearbox: "Gearbox",
};

export type ToyotaIconVariantType = keyof typeof ToyotaIconVariants;
